import { Injectable } from "@angular/core";
import { User } from "../interface_new/user";
import { License, LicenseType } from "../interface_new/license";
import firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentData,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import {
  environment,
  firestore,
  global,
  img,
  message,
  role,
  url,
} from "../../../environments/environment";
import swal from "sweetalert2";
import { UserService } from "./user.service";
import { LicenseService } from "./license.service";
import { DataService } from "./data.service";
import { GarageService } from "./garage.service";
import { Garage, GarageRole } from "../interface_new/garage";
import { TicketService } from "./ticket.service";
import { MonthlyTicket, MonthlyTicketHourly } from "../interface_new/ticket";
import { PaymentService } from "./payment.service";
import { MonthlyPayment } from "../interface_new/payment";
import { HourlyTicketService } from "./hourly-ticket.service";
import { HourlyReservationDocument } from "../interface_new/hourlyReservation";
import { NotificationService } from "./notification.service";
import { NotificationDocument } from "../interface_new/notification";
import { PaypalService } from "./paypal.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { PartnerService } from "./partner.service";
import { Partner } from "../interface_new/partner";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser: User = null;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public userService: UserService,
    public licenseService: LicenseService,
    public ticketService: TicketService,
    public garageService: GarageService,
    public dataService: DataService,
    public paymentService: PaymentService,
    public hourlyTicketService: HourlyTicketService,
    public notificationService: NotificationService,
    public paypalService: PaypalService,
    public partnerService: PartnerService
  ) {
    this.afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        var loaderCounter = 8;
        this.dataService.setLoaderCounter(loaderCounter);
        this.dataService.loaderCounter.subscribe((loaderCounter) => {
          if (loaderCounter <= 0) this.dataService.isLoading(false);
        });

        this.licenseService
          .getLicenseTypeCollection()
          .valueChanges()
          .subscribe(
            (licenseTypeList) => {
              this.dataService.setLicenseTypeList(
                licenseTypeList as LicenseType[]
              );
            },
            (error) => {
              this.notificationService.koNotification(
                message.license.licenseTypeDataReadError
              );
            }
          );
        var userDocument = this.userService.getUserDocument(user.uid);
        userDocument.valueChanges().subscribe(
          (userData) => {
            if (userData === undefined) {
              var providerArray = user.providerData;
              for (let i = 0; i < providerArray.length; i++) {
                if (providerArray[i].providerId == global.googleProvider) {
                  this.garageService
                    .isAdminUser(user.email)
                    .get()
                    .then((data) => {
                      var currentRole = role.owner;
                      if (data.docs.length > 0) {
                        currentRole = role.admin;
                        var userToSave: User = {
                          userId: user.uid,
                          email: user.email,
                          name: "",
                          surname: "",
                          displayName: user.displayName,
                          photoURL: user.photoURL,
                          createdAt: firebase.firestore.Timestamp.fromDate(
                            new Date()
                          ),
                          updatedAt: firebase.firestore.Timestamp.fromDate(
                            new Date()
                          ),
                          notificationList: [],
                          license: null,
                          token: "",
                        };
                        userDocument.set(userToSave);
                      } else {
                        this.garageService
                          .isEmployeeUser(user.email)
                          .get()
                          .then((data) => {
                            if (data.docs.length > 0)
                              currentRole = role.employee;
                            var userToSave: User = {
                              userId: user.uid,
                              email: user.email,
                              name: "",
                              surname: "",
                              displayName: user.displayName,
                              photoURL: user.photoURL,
                              createdAt: firebase.firestore.Timestamp.fromDate(
                                new Date()
                              ),
                              updatedAt: firebase.firestore.Timestamp.fromDate(
                                new Date()
                              ),
                              notificationList: [],
                              license: null,
                              token: "",
                            };
                            userDocument.set(userToSave);
                          });
                      }
                    });
                }
              }
            } else {
              loaderCounter = loaderCounter - 1;
              this.dataService.setLoaderCounter(loaderCounter);
              var currentUser = userData as User;
              this.dataService.setUser(currentUser);
              if (currentUser !== undefined && currentUser != null) {
                //USER WITH LICENSE
                if (
                  currentUser.license !== undefined &&
                  currentUser.license != null
                ) {
                  if (
                    currentUser.license !== undefined &&
                    currentUser.license != null
                  ) {
                    this.licenseService
                      .getLicenseDocument(currentUser.license.id)
                      .valueChanges()
                      .subscribe(
                        (licenseData) => {
                          loaderCounter = loaderCounter - 1;
                          this.dataService.setLoaderCounter(loaderCounter);
                          var currentLicense = licenseData as License;
                          this.dataService.setLicense(currentLicense);

                          if (
                            currentLicense !== undefined &&
                            currentLicense.isSubscriptionActive
                          ) {
                            this.paypalService.getSubsciption(
                              currentLicense.subscriptionId
                            );
                            this.dataService.subscription.subscribe((sub) => {
                              if (sub != null) {
                                if (
                                  sub.status != "ACTIVE" &&
                                  !currentLicense.isExpired &&
                                  this.licenseService.calculateDiff(
                                    currentLicense.expireDate.toDate()
                                  ) < 0
                                ) {
                                  currentLicense.isSubscriptionActive = false;
                                  currentLicense.isExpired = true;
                                  this.licenseService
                                    .updateLicense(currentLicense)
                                    .then(
                                      () => {},
                                      () => {
                                        this.afAuth.auth.signOut();
                                      }
                                    );
                                } else {
                                  if (
                                    currentLicense.expireDate
                                      .toDate()
                                      .getTime() <
                                    new Date(
                                      sub.billing_info.next_billing_time
                                    ).getTime()
                                  ) {
                                    currentLicense.expireDate =
                                      firebase.firestore.Timestamp.fromDate(
                                        new Date(
                                          sub.billing_info.next_billing_time
                                        )
                                      );
                                    currentLicense.isExpired = false;
                                    this.licenseService.updateLicense(
                                      currentLicense
                                    );
                                  }
                                }
                              }
                            });
                          } else if (
                            this.licenseService.calculateDiff(
                              currentLicense.expireDate.toDate()
                            ) < 0 &&
                            !currentLicense.isExpired
                          ) {
                            this.licenseService
                              .setExpireLicense(currentLicense.licenseId, true)
                              .then(
                                () => {},
                                () => {
                                  this.afAuth.auth.signOut();
                                }
                              );
                          }

                          if (
                            currentLicense.garageList !== undefined &&
                            currentLicense.garageList != null &&
                            currentLicense.garageList.length > 0
                          ) {
                            this.garageService
                              .getGarageDocumentList(currentLicense.garageList)
                              .onSnapshot((querySnapshot) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var garageList: GarageRole[] =
                                  querySnapshot.docs.map((e) => {
                                    var garage = e.data() as Garage;
                                    if (garage.priceList !== undefined) {
                                      garage.priceList = garage.priceList.sort(
                                        (one, two) =>
                                          one.name.toLowerCase() >
                                          two.name.toLowerCase()
                                            ? 1
                                            : -1
                                      );
                                    }
                                    if (garage.hourlyRateList !== undefined) {
                                      garage.hourlyRateList =
                                        garage.hourlyRateList.sort((one, two) =>
                                          one.name.toLowerCase() >
                                          two.name.toLowerCase()
                                            ? 1
                                            : -1
                                        );
                                    }
                                    return { garage: garage, role: role.owner };
                                  });
                                this.dataService.setGarageRole(garageList);
                                this.dataService.setGarageList(
                                  garageList.map((obj) => obj.garage)
                                );
                                var garageIdList = garageList.map(
                                  (obj) => obj.garage.garageId
                                );
                                this.partnerService
                                  .getPartnerlist(garageIdList)
                                  .onSnapshot((querySnapshot) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var partneDocsData = querySnapshot.docs.map(
                                      (e) => e.data() as Partner
                                    );
                                    this.dataService.setPartnerList(
                                      partneDocsData
                                    );
                                  });
                                this.ticketService
                                  .getMonthYearTicketDocumentList(garageIdList)
                                  .onSnapshot((data) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var ticketDocsData = data.docs.map(
                                      (e) => e.data() as MonthlyTicket
                                    );
                                    this.dataService.setGarageTicketList(
                                      ticketDocsData
                                    );
                                  });
                                this.notificationService
                                  .getNotificationDocumentList(garageIdList)
                                  .onSnapshot((data) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var notificationDocData = data.docs.map(
                                      (e) => e.data() as NotificationDocument
                                    );
                                    this.dataService.setNotificationList(
                                      notificationDocData
                                    );
                                  });
                                this.paymentService
                                  .getMonthlyPaymentDocumentList(garageIdList)
                                  .onSnapshot((data) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var paymentDocsData = data.docs.map(
                                      (e) => e.data() as MonthlyPayment
                                    );
                                    this.dataService.setGaragePaymentList(
                                      paymentDocsData
                                    );
                                  });
                                this.hourlyTicketService
                                  .getHourlyTicketDocumentList(garageIdList)
                                  .onSnapshot((data) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var hourlyTicketDocsData = data.docs.map(
                                      (e) => e.data() as MonthlyTicketHourly
                                    );
                                    this.dataService.setGarageHourlyTicketList(
                                      hourlyTicketDocsData
                                    );
                                  });
                                this.hourlyTicketService
                                  .getHourlyReservationDocumentList(
                                    garageIdList
                                  )
                                  .onSnapshot((data) => {
                                    loaderCounter = loaderCounter - 1;
                                    this.dataService.setLoaderCounter(
                                      loaderCounter
                                    );
                                    var hourlyReservationDocsData =
                                      data.docs.map(
                                        (e) =>
                                          e.data() as HourlyReservationDocument
                                      );
                                    this.dataService.setGarageHourlyReservationList(
                                      hourlyReservationDocsData
                                    );
                                  });
                              });
                          } else {
                            this.dataService.setLoaderCounter(0);
                          }
                        },
                        (error) => {
                          this.notificationService.koNotification(
                            message.license.licenseDataReadError
                          );
                        }
                      );
                  } else {
                    this.dataService.setLoaderCounter(0);
                  }
                } else {
                  // ADMIN USER
                  this.garageService
                    .isAdminUser(currentUser.email)
                    .onSnapshot((adminQuery) => {
                      var garageList: GarageRole[] = [];
                      // AGGIUNGO GARAGE ADMIN
                      if (adminQuery.docs) {
                        var adminList: GarageRole[] = adminQuery.docs.map(
                          (e) => {
                            var garage = e.data() as Garage;
                            if (garage.priceList !== undefined) {
                              garage.priceList = garage.priceList.sort(
                                (one, two) =>
                                  one.name.toLowerCase() >
                                  two.name.toLowerCase()
                                    ? 1
                                    : -1
                              );
                            }
                            if (garage.hourlyRateList !== undefined) {
                              garage.hourlyRateList =
                                garage.hourlyRateList.sort((one, two) =>
                                  one.name.toLowerCase() >
                                  two.name.toLowerCase()
                                    ? 1
                                    : -1
                                );
                            }
                            return { garage: garage, role: role.admin };
                          }
                        );
                        garageList = garageList.concat(adminList);
                      }

                      this.garageService
                        .isEmployeeUser(currentUser.email)
                        .onSnapshot((employeeQuery) => {
                          // AGGIUNGO GARAGE EMPLOYEE
                          if (employeeQuery.docs) {
                            var employeeList: GarageRole[] =
                              employeeQuery.docs.map((e) => {
                                var garage = e.data() as Garage;
                                if (garage.priceList !== undefined) {
                                  garage.priceList = garage.priceList.sort(
                                    (one, two) =>
                                      one.name.toLowerCase() >
                                      two.name.toLowerCase()
                                        ? 1
                                        : -1
                                  );
                                }
                                if (garage.hourlyRateList !== undefined) {
                                  garage.hourlyRateList =
                                    garage.hourlyRateList.sort((one, two) =>
                                      one.name.toLowerCase() >
                                      two.name.toLowerCase()
                                        ? 1
                                        : -1
                                    );
                                }
                                return { garage: garage, role: role.employee };
                              });
                            garageList = garageList.concat(employeeList);
                          }

                          if (garageList.length == 0) {
                            this.dataService.setGarageList(null);
                            this.dataService.setGarageRole(null);
                            this.dataService.setLicense(null);
                            loaderCounter = 0;
                            this.dataService.setLoaderCounter(loaderCounter);
                          } else {
                            loaderCounter = loaderCounter - 1;
                            this.dataService.setLoaderCounter(loaderCounter);
                            var garageIdList = garageList.map(
                              (obj) => obj.garage.garageId
                            );
                            this.dataService.setGarageList(
                              garageList.map((obj) => obj.garage)
                            );
                            this.dataService.setGarageRole(garageList);

                            this.partnerService
                              .getPartnerlist(garageIdList)
                              .onSnapshot((querySnapshot) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var partneDocsData = querySnapshot.docs.map(
                                  (e) => e.data() as Partner
                                );
                                this.dataService.setPartnerList(partneDocsData);
                              });

                            this.ticketService
                              .getMonthYearTicketDocumentList(garageIdList)
                              .onSnapshot((data) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var ticketDocsData = data.docs.map(
                                  (e) => e.data() as MonthlyTicket
                                );
                                this.dataService.setGarageTicketList(
                                  ticketDocsData
                                );
                              });
                            this.paymentService
                              .getMonthlyPaymentDocumentList(garageIdList)
                              .onSnapshot((data) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var paymentDocsData = data.docs.map(
                                  (e) => e.data() as MonthlyPayment
                                );
                                this.dataService.setGaragePaymentList(
                                  paymentDocsData
                                );
                              });
                            this.hourlyTicketService
                              .getHourlyTicketDocumentList(garageIdList)
                              .onSnapshot((data) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var hourlyTicketDocsData = data.docs.map(
                                  (e) => e.data() as MonthlyTicketHourly
                                );
                                this.dataService.setGarageHourlyTicketList(
                                  hourlyTicketDocsData
                                );
                              });
                            this.notificationService
                              .getNotificationDocumentList(garageIdList)
                              .onSnapshot((data) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var notificationDocData = data.docs.map(
                                  (e) => e.data() as NotificationDocument
                                );
                                this.dataService.setNotificationList(
                                  notificationDocData
                                );
                              });
                            this.hourlyTicketService
                              .getHourlyReservationDocumentList(garageIdList)
                              .onSnapshot((data) => {
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );
                                var hourlyReservationDocsData = data.docs.map(
                                  (e) => e.data() as HourlyReservationDocument
                                );
                                this.dataService.setGarageHourlyReservationList(
                                  hourlyReservationDocsData
                                );
                              });
                            var sub = this.licenseService
                              .getLicenseDocument(
                                garageList[0].garage.license.id
                              )
                              .get()
                              .subscribe((licenseData) => {
                                var currentLicense =
                                  licenseData.data() as License;
                                this.dataService.setLicense(currentLicense);
                                if (currentLicense.isSubscriptionActive) {
                                  this.paypalService.getSubsciption(
                                    currentLicense.subscriptionId
                                  );
                                  this.dataService.subscription.subscribe(
                                    (subcription) => {
                                      if (subcription != null) {
                                        if (
                                          subcription.status != "ACTIVE" &&
                                          !currentLicense.isExpired &&
                                          this.licenseService.calculateDiff(
                                            currentLicense.expireDate.toDate()
                                          ) < 0
                                        ) {
                                          currentLicense.isSubscriptionActive =
                                            false;
                                          currentLicense.isExpired = true;
                                          this.licenseService
                                            .updateLicense(currentLicense)
                                            .then(
                                              () => {},
                                              () => {
                                                this.afAuth.auth.signOut();
                                              }
                                            );
                                        } else {
                                          if (
                                            currentLicense.expireDate
                                              .toDate()
                                              .getTime() <
                                            new Date(
                                              subcription.billing_info.next_billing_time
                                            ).getTime()
                                          ) {
                                            currentLicense.expireDate =
                                              firebase.firestore.Timestamp.fromDate(
                                                new Date(
                                                  subcription.billing_info.next_billing_time
                                                )
                                              );
                                            currentLicense.isExpired = false;
                                            this.licenseService.updateLicense(
                                              currentLicense
                                            );
                                          }
                                        }
                                      }
                                    }
                                  );
                                } else if (
                                  this.licenseService.calculateDiff(
                                    currentLicense.expireDate.toDate()
                                  ) < 0 &&
                                  !currentLicense.isExpired
                                ) {
                                  this.licenseService
                                    .setExpireLicense(
                                      currentLicense.licenseId,
                                      true
                                    )
                                    .then(
                                      () => {},
                                      () => {
                                        this.afAuth.auth.signOut();
                                      }
                                    );
                                }
                                loaderCounter = loaderCounter - 1;
                                this.dataService.setLoaderCounter(
                                  loaderCounter
                                );

                                sub.unsubscribe();
                              });
                          }
                        });
                    });

                  //
                }
              } else {
                this.afAuth.auth.signOut();
              }
            }
          },
          (error) => {
            this.notificationService.koNotification(
              message.user.userDataReadError
            );
          }
        );
      } else {
        this.dataService.isLoading(false);
      }
    });
  }

  // Sign in  with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password).then(
      (success) => {
        if (success.user.emailVerified) {
          this.dataService.isLoading(true);
        } else this.router.navigate([url.verifyEmail.path]);
      },
      (error) => {
        if (error.code == global.login.invalidEmailErrorCode) {
          swal({
            title: global.login.invalidLoginTitle,
            text: global.login.invalidEmailErrorMessage,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        } else if ((error.code = global.login.wrongPasswordErrorCode)) {
          swal({
            title: global.login.invalidLoginTitle,
            text: global.login.wrongPasswordErrorMessage,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        } else {
          swal({
            title: global.login.invalidLoginTitle,
            text: error.message,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        }
      }
    );
  }

  // Sign up with email/password
  SignUp(registerUser) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(registerUser.email, registerUser.password)
      .then((result) => {
        var currentrole = role.owner;
        this.garageService
          .isAdminUser(result.user.email)
          .get()
          .then((data) => {
            if (data.docs.length > 0) {
              currentrole = role.admin;
              this.SaveUsernamePasswordUser(result.user, registerUser).then(
                (success) => {
                  this.SendVerificationMail();
                },
                (error) => {
                  this.afAuth.auth.currentUser.delete().then(() => {
                    swal({
                      title: global.login.invalidRegistrationTitle,
                      text: error.message,
                      type: "error",
                      timer: 4000,
                      showConfirmButton: false,
                      buttonsStyling: false,
                    })
                      .then(() => this.router.navigate([url.registration.path]))
                      .catch(swal.noop);
                  });
                }
              );
            } else {
              this.garageService
                .isEmployeeUser(result.user.email)
                .get()
                .then((data) => {
                  if (data.docs.length > 0) currentrole = role.employee;

                  this.SaveUsernamePasswordUser(result.user, registerUser).then(
                    (success) => {
                      this.SendVerificationMail();
                    },
                    (error) => {
                      this.afAuth.auth.currentUser.delete().then(() => {
                        swal({
                          title: global.login.invalidRegistrationTitle,
                          text: error.message,
                          type: "error",
                          timer: 4000,
                          showConfirmButton: false,
                          buttonsStyling: false,
                        })
                          .then(() =>
                            this.router.navigate([url.registration.path])
                          )
                          .catch(swal.noop);
                      });
                    }
                  );
                });
            }
          });
      })
      .catch((error) => {
        swal({
          title: global.login.invalidRegistrationTitle,
          text: error.message,
          type: "error",
          timer: 4000,
          showConfirmButton: false,
          buttonsStyling: false,
        }).catch(swal.noop);
      });
  }

  SaveUsernamePasswordUser(user, registerUser): Promise<void> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      firestore.collection.user + `/${user.uid}`
    );
    const userData: User = {
      userId: user.uid,
      email: user.email,
      name: registerUser.firstName,
      surname: registerUser.lastName,
      displayName: registerUser.firstName + " " + registerUser.lastName,
      photoURL: img.userImgPlaceholder,
      createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      notificationList: [],
      token: "",
      license: null,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      this.router.navigate([url.verifyEmail.path]);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        swal({
          title: global.login.sentRequestTitle,
          text: global.login.sentRequestMessage,
          type: "success",
          timer: 4000,
          showConfirmButton: false,
          buttonsStyling: false,
        }).catch(swal.noop);
      })
      .catch((error) => {
        if (error.code == global.login.invalidEmailErrorCode) {
          swal({
            title: global.login.resetPasswordErrorTitle,
            text: global.login.resetPasswordInvalidEmailMessage,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        } else if (error.code == global.login.userNotFoundErrorCode) {
          swal({
            title: global.login.resetPasswordErrorTitle,
            text: global.login.userNotFoundErrorMessage,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        } else {
          swal({
            title: global.login.resetPasswordErrorTitle,
            text: error.message,
            type: "error",
            timer: 4000,
            showConfirmButton: false,
            buttonsStyling: false,
          }).catch(swal.noop);
        }
      });
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthGoogleRegister(new firebase.auth.GoogleAuthProvider());
  }

  AuthGoogleRegister(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((result) => {
        this.dataService.isLoading(true);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut();
  }

  resetPassword(code: string, password: string) {
    return this.afAuth.auth.confirmPasswordReset(code, password);
  }

  verifyEmail(code: string) {
    return this.afAuth.auth.applyActionCode(code);
  }

  public checkAuthentication(): Observable<boolean> {
    return this.afAuth.user.pipe(
      map((user) => {
        if (user) {
          if (!user.emailVerified) {
            this.router.navigate([url.verifyEmail.path]);
          }
          return true;
        } else {
          this.router.navigate([url.login.path]);
          return false;
        }
      })
    );
  }
}
