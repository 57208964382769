<div *ngIf="isloading" class="overlay">
  <div class="">
    <img
      class="img"
      src="./assets/img/logo_esteso_positivo.svg"
      style="padding: 200px"
    />
  </div>
  <div class="loader">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>
<div *ngIf="isDownloading" class="overlay overlay-download">
  <div class="">
    <img
      class="img"
      src="./assets/img/logo_esteso_positivo.svg"
      style="padding: 200px"
    />
  </div>
  <div class="loader">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</div>
<router-outlet></router-outlet>
