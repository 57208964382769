import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { Observable } from "rxjs";
import { global, firestore, message } from "../../../environments/environment";
import { BillingInfo, User } from "../interface_new/user";
import { NotificationService } from "./notification.service";
import * as firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class UserService {
  userCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public notificationService: NotificationService,
    private afStorage: AngularFireStorage
  ) {
    this.userCollection = this.afs.collection<User>(firestore.collection.user);
  }

  getUserDocument(uid: string): AngularFirestoreDocument {
    return this.userCollection.doc(uid);
  }

  getuUserDocumentReference(uid: string): DocumentReference {
    return this.userCollection.doc(uid).ref;
  }

  getUserFromEmail(email: string): any {
    return this.afs
      .collection(firestore.collection.user, (ref) =>
        ref.where("email", "==", email).limit(1)
      )
      .valueChanges();
  }

  updateUserLicense(
    user: AngularFirestoreDocument,
    license: DocumentReference
  ) {
    user
      .update({ license: license, activeLicense: true })
      .catch((error) => this.notificationService.koNotification(error));
  }

  disableUserLicense(user: AngularFirestoreDocument) {
    user
      .update({ license: null, activeLicense: false })
      .catch((error) => this.notificationService.koNotification(error));
  }

  updateUserLicenseAdmin(
    user: AngularFirestoreDocument,
    license: DocumentReference
  ) {
    user
      .update({ license: license, activeLicense: true, isLicenseAdmin: true })
      .catch((error) => this.notificationService.koNotification(error));
  }

  updateUserName(
    user: User,
    name: string,
    surname: string,
    displayName: string
  ) {
    this.getUserDocument(user.userId)
      .update({ name: name, surname: surname, displayName: displayName })
      .then(
        (success) =>
          this.notificationService.okNotification(
            message.user.userUpdateSuccess
          ),
        (error) =>
          this.notificationService.koNotification(message.user.imageUploadError)
      );
  }

  updateUserBillingInfo(user: User, billingInfo: BillingInfo) {
    this.getUserDocument(user.userId)
      .update({ billingInfo: billingInfo })
      .then(
        (success) =>
          this.notificationService.okNotification(
            message.user.userUpdateSuccess
          ),
        (error) =>
          this.notificationService.koNotification(message.user.imageUploadError)
      );
  }

  uploadUserImage(userId, logo): AngularFireUploadTask {
    return this.afStorage
      .ref(firestore.storage.userImgFolder + userId)
      .put(logo);
  }

  getUserImageUrl(userId): Observable<any> {
    return this.afStorage
      .ref(firestore.storage.userImgFolder + userId)
      .getDownloadURL();
  }

  updateUserImage(userId, userImageUrl): Promise<void> {
    return this.userCollection.doc(userId).update({
      photoURL: userImageUrl,
      updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    });
  }

  updateNotificationList(userId, notificationList): Promise<void> {
    return this.userCollection
      .doc(userId)
      .update({ notificationList: notificationList });
  }
}
