import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from "@angular/fire/firestore";
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from "@angular/fire/storage";

import { AngularFireFunctions } from "@angular/fire/functions";
import { Observable } from "rxjs";
import {
  firestore,
  img,
  message,
  model,
} from "../../../environments/environment";
import { Garage, GarageMin } from "../interface_new/garage";
import { ToDoService } from "../interface_new/service";
import { DataService } from "./data.service";
import { LicenseService } from "./license.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class GarageService {
  garageCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public notificationService: NotificationService,
    public licenseService: LicenseService,
    public dataService: DataService,
    private afStorage: AngularFireStorage
  ) {
    this.garageCollection = afs.collection<Garage>(firestore.collection.garage);
  }

  garageToGarageMin(garage: Garage): GarageMin {
    return {
      garageId: garage.garageId,
      name: garage.name,
      description: garage.description,
      fiscalCode: garage.fiscalCode,
      phoneNumber: garage.phoneNumber,
      logo: garage.logo,
      mobilePhoneNumber: garage.mobilePhoneNumber,
      email: garage.email,
      address: garage.address,
    };
  }

  generateGarageId(): string {
    return this.afs.createId();
  }

  createEmptyGarage(): Garage {
    return {
      garageId: "",
      name: model.garage.emptyName,
      description: model.garage.emptyDesc,
      fiscalCode: "",
      phoneNumber: "",
      mobilePhoneNumber: "",
      email: "",
      user: null,
      seatsNumber: 0,
      priceList: [],
      hourlyRateList: [],
      address: null,
      adminList: [],
      vehicleList: [],
      customerList: [],
      employeeList: [],
      serviceList: [],
      contractText: "",
      toDoServiceList: [],
      printerList: [],
      partnerList: [],
      logo: img.garageImgPlaceholder,
      license: null,
      deletionDate: null,
      notificationType: {
        isReminderActive: false,
      },
      paymentDay: 0,
    };
  }

  isAdminUser(email: string): DocumentData {
    return this.garageCollection.ref.where(
      "adminList",
      "array-contains",
      email
    );
  }

  isEmployeeUser(email: string): DocumentData {
    return this.garageCollection.ref.where(
      "employeeList",
      "array-contains",
      email
    );
  }

  deleteGarage(garage: Garage): Promise<void> {
    return this.afs
      .collection(firestore.collection.garage)
      .doc(garage.garageId)
      .delete();
  }

  getGarageDocumentList(garageList: string[]): DocumentData {
    return this.garageCollection.ref.where("garageId", "in", garageList);
  }

  createNewGarage(garage: Garage): Promise<void> {
    return this.garageCollection.doc(garage.garageId).set(garage);
  }

  updateGarage(garage: Garage): Promise<void> {
    return this.garageCollection.doc(garage.garageId).update(garage);
  }

  uploadGarageLogo(garageId, logo): AngularFireUploadTask {
    return this.afStorage
      .ref(firestore.storage.garageImgFolder + garageId)
      .put(logo);
  }

  getGarageLogoUrl(garageId): Observable<any> {
    return this.afStorage
      .ref(firestore.storage.garageImgFolder + garageId)
      .getDownloadURL();
  }

  updateGarageLogo(garageId, logoUrl): Promise<void> {
    return this.garageCollection.doc(garageId).update({ logo: logoUrl });
  }

  updateGaragePriceList(garageId, priceList): Promise<void> {
    return this.garageCollection.doc(garageId).update({ priceList: priceList });
  }

  updateGarageConventionPriceList(garageId, priceList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ conventionPriceList: priceList });
  }

  updateGaragePriceListAndVehicle(
    garageId,
    priceList,
    vehicleList
  ): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ priceList: priceList, vehicleList: vehicleList });
  }

  updateGarageHourlyPriceList(garageId, hourlyPriceList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ hourlyRateList: hourlyPriceList });
  }

  updateGarageServiceList(garageId, serviceList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ serviceList: serviceList });
  }

  updateGarageAdminList(garageId, adminList): Promise<void> {
    return this.garageCollection.doc(garageId).update({ adminList: adminList });
  }

  updateGarageEmployeeList(garageId, employeeList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ employeeList: employeeList });
  }

  uploadGarageContract(garageId, contractText): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ contractText: contractText });
  }

  updateCustomerAndVehicleList(
    garageId,
    customerList,
    vehicleList
  ): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ customerList: customerList, vehicleList: vehicleList });
  }

  updateToDoServiceList(garageId, toDoServiceList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ toDoServiceList: toDoServiceList });
  }

  updatePrinterList(garageId, printerList): Promise<void> {
    return this.garageCollection
      .doc(garageId)
      .update({ printerList: printerList });
  }

  updatePaymentDay(garageId, paymentReminder): Promise<void> {
    return this.garageCollection.doc(garageId).update({
      paymentDay: paymentReminder.paymentDay,
      notificationType: {
        isReminderActive: paymentReminder.isReminderActive,
      },
    });
  }

  downloadToDoServiceList(openService: ToDoService[], garage: Garage) {
    var data = this.functionAgendaData(openService, garage);
    this.aff
      .httpsCallable(firestore.function.downloadToDoServiceList)(data)
      .subscribe(
        async (res) => {
          this.dataService.isDownLoading(false);
          if (res && res[0]) {
            await window.open(res[0]);
          }
        },
        (err) => {
          this.dataService.isDownLoading(false);

          this.notificationService.koNotification(
            message.toDoService.toDoServiceDownloadError
          );
        }
      );
  }

  functionAgendaData(toDoServiceList: ToDoService[], garage: Garage): any {
    var garageTOSend = {
      garageId: garage.garageId,
      address: garage?.address?.formatted_address,
      description: garage?.description,
      email: garage?.email,
      fiscalCode: garage.fiscalCode,
      logo: garage.logo,
      mobilePhoneNumber: garage.mobilePhoneNumber,
      phoneNumber: garage.phoneNumber,
      name: garage.name,
      customerList: garage.customerList,
      vehicleList: garage.vehicleList,
    };
    return {
      toDoServiceList: toDoServiceList,
      garage: garageTOSend,
    };
  }
}
