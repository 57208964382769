import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { Garage } from "src/app/_shared/interface_new/garage";
import {
  Notification,
  NotificationDocument,
} from "src/app/_shared/interface_new/notification";
import { User } from "src/app/_shared/interface_new/user";
import { DataService } from "src/app/_shared/services/data.service";
import { GarageService } from "src/app/_shared/services/garage.service";
import { NotificationService } from "src/app/_shared/services/notification.service";
import { UserService } from "src/app/_shared/services/user.service";
import { message } from "src/environments/environment";

declare const $: any;
const md: any = {
  misc: {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
  },
};

@Component({
  selector: "app-fixedplugin",
  templateUrl: "./fixedplugin.component.html",
  styleUrls: ["./fixedplugin.component.css"],
})
export class FixedpluginComponent implements OnInit {
  currentUser: User;
  garageList: Garage[] = [];
  userNotificationList: Notification[] = [];
  otherNotificationList: NotificationDocument[] = [];
  notificationList: Notification[] = [];
  unReadNotification: number = 0;

  currentNotification: string = "";

  constructor(
    public dataService: DataService,
    public userService: UserService,
    public notificationService: NotificationService,
    public garageService: GarageService,
    private router: Router
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
      }
    });
  }

  ngOnInit() {
    this.dataService.currentUser.subscribe((user) => {
      if (user != null) {
        this.currentUser = user;
        if (
          this.currentUser.notificationList !== undefined &&
          this.currentUser.notificationList != null
        ) {
          this.currentUser.notificationList.forEach((obj) => {
            var index = this.notificationList.findIndex(
              (inObj) => inObj.notificationId == obj.notificationId
            );
            if (index >= 0) this.notificationList[index] = obj;
            else this.notificationList.push(obj);
            var index2 = this.userNotificationList.findIndex(
              (inObj) => inObj.notificationId == obj.notificationId
            );
            if (index2 >= 0) this.userNotificationList[index2] = obj;
            else this.userNotificationList.push(obj);
          });

          this.unReadNotification = this.notificationList.filter(
            (obj) => !obj.isRead
          ).length;
        }
      }
    });

    this.dataService.notificationList.subscribe((notificationDoc) => {
      if (notificationDoc != null) {
        this.otherNotificationList = notificationDoc;
        notificationDoc.forEach((element) => {
          if (
            element.notificationList != null &&
            element.notificationList !== undefined
          ) {
            element.notificationList.forEach((obj) => {
              var index = this.notificationList.findIndex(
                (inObj) => inObj.notificationId == obj.notificationId
              );
              obj.garageId = element.garageId;
              if (index >= 0) this.notificationList[index] = obj;
              else this.notificationList.push(obj);
            });
            this.unReadNotification = this.notificationList.filter(
              (obj) => !obj.isRead
            ).length;
          }
        });
      }
    });

    this.dataService.currentGarageList.subscribe((garageList) => {
      if (garageList != null) {
        this.garageList = garageList;
      }
    });

    // fixed plugin
    const $sidebar = $(".sidebar");
    const $sidebar_img_container = $sidebar.find(".sidebar-background");
    //
    const $full_page = $(".full-page");
    //
    const $sidebar_responsive = $("body > .navbar-collapse");
    const window_width = $(window).width();

    const fixed_plugin_open = $(
      ".sidebar .sidebar-wrapper .nav li.active a p"
    ).html();

    if (window_width > 767 && fixed_plugin_open === "Dashboard") {
      if ($(".fixed-plugin .dropdown").hasClass("show-dropdown")) {
        $(".fixed-plugin .dropdown").addClass("open");
      }
    }
  }

  resolveNotification(notification, button) {
    if (
      notification.data !== undefined &&
      notification.data.serviceId !== undefined
    ) {
      var action =
        button.action.split("/")[button.action.split("/").length - 1];
      if (action != "edit") {
        var notificationDoc = this.otherNotificationList.find(
          (obj) => obj.garageId == notification.garageId
        );
        if (notificationDoc !== undefined) {
          var index = notificationDoc.notificationList.findIndex(
            (inObj) => inObj.notificationId == notification.notificationId
          );
          if (index >= 0) {
            notificationDoc.notificationList[index].readDate =
              firebase.firestore.Timestamp.now();
            notificationDoc.notificationList[index].isRead = true;
            notificationDoc.notificationList[index].data.status =
              action == "accept" ? "approved" : "rejectedfromGarage";
            this.notificationService.updateNotificationService(
              notification.garageId,
              notificationDoc.notificationList
            );
          } else {
            this.notificationService.koNotification(
              message.toDoService.serviceNotFound
            );
          }
        } else {
          this.notificationService.koNotification(
            message.toDoService.serviceNotFound
          );
        }
      } else {
        this.router.navigate([button.action]);
      }
    } else {
      this.router.navigate([button.action]);
    }
  }

  removeNotification(notification) {
    if (notification.garageId === undefined) {
      var index = this.currentUser.notificationList.findIndex(
        (obj) => obj.notificationId == notification.notificationId
      );
      if (index >= 0) {
        this.currentUser.notificationList.splice(index, 1);
        this.userService.updateNotificationList(
          this.currentUser.userId,
          this.currentUser.notificationList
        );
      }
    } else {
      var currentDoc = this.otherNotificationList.find(
        (obj) => obj.garageId == notification.garageId
      );
      if (currentDoc !== undefined) {
        var index = currentDoc.notificationList.findIndex(
          (inObj) => inObj.notificationId == notification.notificationId
        );
        currentDoc.notificationList.splice(index, 1);
        this.notificationService.updateNotificationService(
          currentDoc.garageId,
          currentDoc.notificationList
        );
      }
    }
  }

  markAsRead() {
    if (this.unReadNotification > 0) {
      if (
        this.currentUser.notificationList !== undefined &&
        this.currentUser.notificationList.filter((obj) => !obj.isRead) !==
          undefined
      ) {
        this.currentUser.notificationList.forEach((obj) => {
          (obj.isRead = true),
            (obj.readDate = firebase.firestore.Timestamp.now());
        });
        this.userService.updateNotificationList(
          this.currentUser.userId,
          this.currentUser.notificationList
        );
      }
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.userService) {
      return true;
    }
    return false;
  }
}
