import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import {
  firestore,
  global,
  message,
  url,
} from "../../../environments/environment";

import { AngularFireFunctions } from "@angular/fire/functions";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import { License, LicensePayment, LicenseType } from "../interface_new/license";
import { User } from "../interface_new/user";
import { NotificationService } from "./notification.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class LicenseService {
  licenseCollection: AngularFirestoreCollection;
  licenseTypeCollection: AngularFirestoreCollection;

  constructor(
    public aff: AngularFireFunctions,
    public afs: AngularFirestore,
    public notificationService: NotificationService,
    public userService: UserService,
    public router: Router
  ) {
    this.licenseCollection = afs.collection<License>(
      firestore.collection.license
    );
    this.licenseTypeCollection = afs.collection<LicenseType>(
      firestore.collection.licenseType
    );
  }

  generateLicenseId(): string {
    return this.afs.createId();
  }

  getLicenseDocument(id) {
    return this.licenseCollection.doc(id);
  }

  getLicenseTypeCollection(): AngularFirestoreCollection {
    return this.afs.collection(firestore.collection.licenseType, (ref) =>
      ref.orderBy("order")
    );
  }

  getExpireDate(startDate: Date, rate: string): Date {
    if (rate == global.rateList[0])
      startDate.setMonth(startDate.getMonth() + 1);
    else if (rate == global.rateList[1])
      startDate.setMonth(startDate.getMonth() + 6);
    else if (rate == global.rateList[2])
      startDate.setMonth(startDate.getMonth() + 12);
    else return null;

    return startDate;
  }

  getLicensePrice(selectedPlan: LicenseType, rate: string): string {
    if (rate == global.rateList[0]) return selectedPlan.monthlyCost + ".00";
    else if (rate == global.rateList[1])
      return selectedPlan.semestralCost + ".00";
    else if (rate == global.rateList[2]) return selectedPlan.annualCost + ".00";
    else return null;
  }

  addNewLicense(license: License): Promise<void> {
    return this.licenseCollection.doc(license.licenseId).set(license);
  }

  getLicenseFromUser(lid: string): Observable<any> {
    return this.licenseCollection.doc(lid).snapshotChanges();
  }

  getLicenseIdFromPath(path: string): string {
    if (path != null && path !== undefined) {
      if (path.split("/").length == 2) return path.split("/")[1];
    }
    return null;
  }

  createEmptyLicense(): any {
    var res = {
      type: "",
      lastPaymentDate: null,
      expireDate: null,
      expired: true,
      maxParkingNumber: 0,
      usedParkingNumber: 0,
      maxAdminNumber: 0,
      usedAdminNumber: 0,
      userAdmins: [],
      payments: null,
    };
    return res;
  }

  calculateDiff(dateSent) {
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.floor(
      (dateSent.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 23)
    );
  }

  addUserToLicense(email, licenseId, license) {
    var licenseRef = this.licenseCollection.doc(licenseId).ref;
    var userData = this.userService.getUserFromEmail(email).subscribe(
      (res) => {
        if (res.empty) return null;
        var userDoc = this.userService.getUserDocument(res[0].uid);
        this.userService.updateUserLicense(userDoc, licenseRef);
        license.userAdmins.push(email);
        this.licenseCollection
          .doc(licenseId)
          .update({
            userAdmins: license.userAdmins,
            usedAdminNumber: license.usedAdminNumber + 1,
          })
          .catch((error) =>
            this.notificationService.koNotification(
              message.garage.addUserToAdminListError
            )
          );

        userData.unsubscribe();
        this.notificationService.okNotification(
          message.garage.addUserToAdminListSuccess
        );
      },
      (error) => {
        this.notificationService.koNotification(message.user.userDataReadError);
      }
    );
  }

  addGarageToLicense(garagePath, licenseId, license) {
    var licenseRef = this.licenseCollection
      .doc(licenseId)
      .get()
      .subscribe(
        (license) => {
          var licenseObj = license.data();
          if (licenseObj.usedParkingNumber + 1 <= licenseObj.maxParkingNumber) {
            licenseObj.garages.push(garagePath);
            licenseObj.usedParkingNumber = licenseObj.usedParkingNumber + 1;
            this.licenseCollection.doc(licenseId).update({
              garages: licenseObj.garages,
              usedParkingNumber: licenseObj.usedParkingNumber,
            });
            licenseRef.unsubscribe();
          }
        },
        (error) => {
          this.notificationService.koNotification(
            message.license.licenseDataReadError
          );
          licenseRef.unsubscribe();
        }
      );
  }

  addPaymentToLicense(payment, expireDate, licenseId, license) {
    license.payments.push(payment);
    this.licenseCollection
      .doc(licenseId)
      .update({
        payments: license.payments,
        expireDate: expireDate,
      })
      .then((data) =>
        swal({
          title: message.license.renewLicenseTitleSuccess,
          text: message.license.renewLicenseSuccess,
          buttonsStyling: false,
          confirmButtonClass: "btn btn-success",
          type: "success",
        })
          .then((result) => {
            if (result.value) {
              this.router.navigate([url.garageAdmin.path]);
            }
          })
          .catch(swal.noop)
      )
      .catch((error) =>
        this.notificationService.koNotification(
          message.license.renewLicenseError
        )
      );
  }

  disableUserToLicense(email, licenseId, license) {
    var licenseRef = this.licenseCollection.doc(licenseId).ref;
    var userData = this.userService.getUserFromEmail(email).subscribe(
      (res) => {
        if (res.empty) return null;
        var userDoc = this.userService.getUserDocument(res[0].uid);
        this.userService.disableUserLicense(userDoc);
        var index = license.userAdmins.indexOf(email);
        if (index !== -1) license.userAdmins.splice(index, 1);
        this.licenseCollection
          .doc(licenseId)
          .update({
            userAdmins: license.userAdmins,
            usedAdminNumber: license.usedAdminNumber - 1,
          })
          .catch((error) =>
            this.notificationService.koNotification(
              message.license.licenseDataUpdateError
            )
          );

        userData.unsubscribe();
        this.notificationService.okNotification(
          message.license.disableUserFromLicense
        );
      },
      (error) => {
        this.notificationService.koNotification(message.user.userDataReadError);
      }
    );
  }

  setExpireLicense(licenseId, expired) {
    return this.licenseCollection
      .doc(licenseId)
      .update({ isExpired: expired })
      .catch((error) =>
        this.notificationService.koNotification(
          message.license.licenseDataUpdateError
        )
      );
  }

  updateLicense(license: License): Promise<void> {
    return this.licenseCollection.doc(license.licenseId).update(license);
  }

  downloadInvoice(
    user: User,
    license: License,
    licensePayment: LicensePayment
  ) {
    var data = {
      user: user,
      license: license,
      licensePayment: licensePayment,
    };

    data.user.license = null;

    this.aff
      .httpsCallable(firestore.function.downloadInvoice)(data)
      .subscribe(
        async (res) => {
          //this.dataService.isDownLoading(false)
          if (res && res[0]) {
            await window.open(res[0]);
          }
        },
        (err) => {
          //this.dataService.isDownLoading(false)

          this.notificationService.koNotification(err.message);
        }
      );
  }
}
