import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject } from "rxjs";
import { Garage, GarageRole } from "../interface_new/garage";
import { HourlyReservationDocument } from "../interface_new/hourlyReservation";
import { License, LicenseType } from "../interface_new/license";
import { Notification } from "../interface_new/notification";
import { Partner } from "../interface_new/partner";
import { MonthlyPayment } from "../interface_new/payment";
import { Settings } from "../interface_new/setting";
import { MonthlyTicket, MonthlyTicketHourly } from "../interface_new/ticket";
import { User } from "../interface_new/user";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private userSource = new BehaviorSubject(null);
  currentUser: Observable<User> = this.userSource.asObservable();

  private licenseSource = new BehaviorSubject(null);
  currentLicense = this.licenseSource.asObservable();

  private garageListSource = new BehaviorSubject(null);
  currentGarageList = this.garageListSource.asObservable();

  private licenseTypeListSource = new BehaviorSubject(null);
  currentLicenseTypeList = this.licenseTypeListSource.asObservable();

  private garageTicketSource = new BehaviorSubject(null);
  currentGarageTicketList = this.garageTicketSource.asObservable();

  private garageArchivedTicketSource = new BehaviorSubject(null);
  currentGarageArchivedTicketList =
    this.garageArchivedTicketSource.asObservable();

  private garageHourlyTicketSource = new BehaviorSubject(null);
  currentGarageHourlyTicketList = this.garageHourlyTicketSource.asObservable();

  private garageHourlyReservationSource = new BehaviorSubject(null);
  currentGarageHourlyReservationList =
    this.garageHourlyReservationSource.asObservable();

  private garagePaymentSource = new BehaviorSubject(null);
  currentGaragePaymentList = this.garagePaymentSource.asObservable();

  private garageArchivedPaymentSource = new BehaviorSubject(null);
  currentGarageArchivedPaymentList =
    this.garageArchivedPaymentSource.asObservable();

  private isTicketPageSource = new BehaviorSubject(false);
  currentIsTicketPage = this.isTicketPageSource.asObservable();

  private loaderSource = new BehaviorSubject(true);
  loader = this.loaderSource.asObservable();

  private downloaderSource = new BehaviorSubject(false);
  downloader = this.downloaderSource.asObservable();

  private loaderCounterSource = new BehaviorSubject(0);
  loaderCounter = this.loaderCounterSource.asObservable();

  private notificationListSource = new BehaviorSubject(null);
  notificationList = this.notificationListSource.asObservable();

  private garageRoleSource = new BehaviorSubject(null);
  garageRole = this.garageRoleSource.asObservable();

  private subscriptionSource = new ReplaySubject<any>(1);
  subscription = this.subscriptionSource.asObservable();

  private subscriptionPaymentSource = new ReplaySubject<any>(1);
  subscriptionPayement = this.subscriptionPaymentSource.asObservable();

  private settingsSource = new BehaviorSubject(null);
  currentSetting: Observable<Settings> = this.settingsSource.asObservable();

  private partnerListSource = new BehaviorSubject(null);
  currentPartnerList: Observable<Partner[]> =
    this.partnerListSource.asObservable();

  constructor() {}

  public setUser(user: User) {
    this.userSource.next(user);
  }

  public setLicense(license: License) {
    this.licenseSource.next(license);
  }

  public setGarageList(garageList: Garage[]) {
    this.garageListSource.next(garageList);
  }

  public setPartnerList(partnerList: Partner[]) {
    this.partnerListSource.next(partnerList);
  }

  public setNotificationList(notificationList: Notification[]) {
    this.notificationListSource.next(notificationList);
  }

  public setLicenseTypeList(licenseTypeList: LicenseType[]) {
    this.licenseTypeListSource.next(licenseTypeList);
  }

  public setGarageTicketList(garageTicketList: MonthlyTicket[]) {
    this.garageTicketSource.next(garageTicketList);
  }

  public setGarageArchivedTicketList(
    garageArchivedTicketList: MonthlyTicket[]
  ) {
    this.garageArchivedTicketSource.next(garageArchivedTicketList);
  }

  public setGarageHourlyTicketList(
    garageHourlyTicketList: MonthlyTicketHourly[]
  ) {
    this.garageHourlyTicketSource.next(garageHourlyTicketList);
  }

  public setGarageHourlyReservationList(
    garageHourlyReservationList: HourlyReservationDocument[]
  ) {
    this.garageHourlyReservationSource.next(garageHourlyReservationList);
  }

  public setGaragePaymentList(garagePaymentList: MonthlyPayment[]) {
    this.garagePaymentSource.next(garagePaymentList);
  }

  public setGarageArchivedPaymentList(
    garageArchivedPaymentList: MonthlyPayment[]
  ) {
    this.garageArchivedPaymentSource.next(garageArchivedPaymentList);
  }

  public setSettings(settings: Settings) {
    this.settingsSource.next(settings);
  }

  public setIsTicketPage(isTicketPage: boolean) {
    this.isTicketPageSource.next(isTicketPage);
  }

  public isLoading(isLoading: boolean) {
    this.loaderSource.next(isLoading);
  }

  public isDownLoading(isLoading: boolean) {
    this.downloaderSource.next(isLoading);
  }

  public setLoaderCounter(counter: number) {
    this.loaderCounterSource.next(counter);
  }

  public setGarageRole(garageRole: GarageRole[]) {
    this.garageRoleSource.next(garageRole);
  }

  public setSubscription(subcription: any) {
    this.subscriptionSource.next(subcription);
  }

  public setSubscriptionPayment(subcriptionPayment: any) {
    this.subscriptionPaymentSource.next(subcriptionPayment);
  }
}
