<!-- Fixed Plugin configurator, used just for Demo Purpose -->
<div
  (click)="markAsRead()"
  [ngClass]="{
    hidden:
      notificationList == null ||
      notificationList === undefined ||
      notificationList.length <= 0
  }"
  class="fixed-plugin"
>
  <div class="dropdown show-dropdown">
    <a href="#" data-toggle="dropdown" aria-expanded="true">
      <i class="fa fa-bell fa-2x"> </i>
      <span *ngIf="unReadNotification > 0" class="notification">{{
        unReadNotification
      }}</span>
    </a>
    <ul class="dropdown-menu">
      <div *ngFor="let notification of notificationList">
        <li class="header-title">
          {{ notification.title
          }}<i
            (click)="removeNotification(notification)"
            class="material-icons pull-right close-icon"
            >close</i
          >
        </li>
        <li style="width: 100%" class="adjustments-line">
          <div class="ml-auto mr-auto">
            <p>{{ notification.body }}</p>
            <p
              class="header-title text-success notification-status"
              *ngIf="notification.data.status == 'approved'"
            >
              Accettato
            </p>
            <p
              class="header-title text-error notification-status"
              *ngIf="notification.data.status == 'rejectedfromGarage'"
            >
              Rigettato
            </p>
          </div>
          <div
            *ngIf="
              notification.data !== undefined &&
              notification.data != null &&
              notification.data.actionList !== undefined &&
              notification.data.actionList != null &&
              notification.data.actionList.length > 0 &&
              (notification.data.serviceId === undefined ||
                notification.data.status == 'waitingForGarageApproval')
            "
          >
            <button
              (click)="resolveNotification(notification, button)"
              *ngFor="let button of notification.data.actionList"
              class="btn btn-primary btn-sm mat-raised-button mat-button-base pull-right"
            >
              {{ button.buttonName }}
            </button>
          </div>
          <div class="clearfix"></div>
        </li>
      </div>
    </ul>
  </div>
</div>
