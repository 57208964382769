import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from "@angular/fire/firestore";
import firebase from "firebase";
import { UsbDriver } from "ng-thermal-print";
import { apiUrl, firestore } from "src/environments/environment";
import { Garage } from "../interface_new/garage";
import {
  HourlyReservation,
  HourlyReservationDocument,
} from "../interface_new/hourlyReservation";
import { MonthlyTicketHourly } from "../interface_new/ticket";
import { TicketService } from "./ticket.service";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class HourlyTicketService {
  hourlyTicketCollection: AngularFirestoreCollection;
  hourlyReservationCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public ticketService: TicketService
  ) {
    this.hourlyTicketCollection = this.afs.collection<MonthlyTicketHourly>(
      firestore.collection.hourlyTicket
    );
    this.hourlyReservationCollection =
      this.afs.collection<HourlyReservationDocument>(
        firestore.collection.hourlyReservation
      );
  }

  createReservationId(): string {
    return this.afs.createId();
  }

  getHourlyTicketDocumentList(garageId: string[]): DocumentData {
    return this.hourlyTicketCollection.ref.where(
      "garage.garageId",
      "in",
      garageId
    );
  }

  getHourlyReservationDocumentList(garageId: string[]): DocumentData {
    return this.hourlyReservationCollection.ref.where(
      "garage.garageId",
      "in",
      garageId
    );
  }

  createReservationDocument(
    garage: Garage,
    currentDate: Date,
    garageReservation: HourlyReservation
  ): Promise<void> {
    return this.hourlyReservationCollection
      .doc(
        this.ticketService.createTicketDocumentId(
          garage.garageId,
          currentDate.getMonth() + 1,
          currentDate.getFullYear()
        )
      )
      .set({
        garage: {
          garageId: garage.garageId,
          name: garage.name,
          description:
            garage.description !== undefined && garage.description != null
              ? garage.description
              : "",
          fiscalCode:
            garage.fiscalCode !== undefined && garage.fiscalCode != null
              ? garage.fiscalCode
              : "",
          phoneNumber:
            garage.phoneNumber !== undefined && garage.phoneNumber != null
              ? garage.phoneNumber
              : "",
          logo:
            garage.logo !== undefined && garage.logo != null ? garage.logo : "",
          mobilePhoneNumber:
            garage.mobilePhoneNumber !== undefined &&
            garage.mobilePhoneNumber != null
              ? garage.mobilePhoneNumber
              : "",
          email:
            garage.email !== undefined && garage.email != null
              ? garage.email
              : "",
          address:
            garage.address !== undefined && garage.address != null
              ? garage.address
              : null,
        },
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        adminList: garage.adminList,
        employeeList:
          garage.employeeList != undefined && garage.employeeList != null
            ? garage.employeeList
            : [],
        ticketList: {
          [currentDate.getDate() < 10
            ? "0" + currentDate.getDate()
            : currentDate.getDate()]: [garageReservation],
        },
      });
  }

  updateReservationList(
    garage: Garage,
    month: number,
    year: number,
    garageReservation: any
  ): Promise<void> {
    return this.hourlyReservationCollection
      .doc(
        this.ticketService.createTicketDocumentId(garage.garageId, month, year)
      )
      .update({
        ticketList: garageReservation,
      });
  }

  updateDeletedReservationList(
    garageId: string,
    month: number,
    year: number,
    garageReservation: any
  ): Promise<void> {
    return this.hourlyReservationCollection
      .doc(this.ticketService.createTicketDocumentId(garageId, month, year))
      .update({
        ticketList: garageReservation,
      });
  }

  printHourlyTicket(
    usbPrintDriver: UsbDriver,
    garage: Garage,
    garageHourlyTicket: HourlyReservation,
    currentDate: Date
  ) {
    let EscPosEncoder = require("esc-pos-encoder");

    const encoder = new EscPosEncoder();
    const documentId =
      garage.garageId +
      "-" +
      moment(garageHourlyTicket?.startDate?.toDate()).format("MM") +
      "-" +
      moment(garageHourlyTicket?.startDate?.toDate()).format("YYYY");

    let _encoder = encoder
      .initialize()
      .align("center")
      .newline()
      .size("normal")
      .bold(true)
      .line(garage.name);

    if (garage.address?.formatted_address) {
      encoder
        .size("small")
        .line(garage.address.formatted_address)
        .size("normal");
    }
    if (garage.mobilePhoneNumber) {
      encoder.line("TEL: " + garage.mobilePhoneNumber);
    } else if (garage.phoneNumber) {
      encoder.line("TEL: " + garage.phoneNumber);
    }

    encoder
      .newline()
      .line("Inizio Sosta")
      .line(this.timestampToString(garageHourlyTicket.startDate));

    if (!garageHourlyTicket.hourlyRate.isTimeCalculation) {
      const isHalfDay = garageHourlyTicket.hourlyRate.isHalfDay;

      encoder.line("Fine Sosta").line(
        moment(garageHourlyTicket?.startDate?.toDate())
          .add(
            isHalfDay ? 12 : garageHourlyTicket.quantity,
            isHalfDay ? "hours" : "days"
          )
          .format("DD/MM/YYYY HH:mm")
      );
    }

    _encoder = encoder
      .newline()
      .qrcode(apiUrl.deepLink + documentId + "/" + garageHourlyTicket.ticketId)
      .newline()
      .bold(true)
      .line(garageHourlyTicket.plate)
      .bold(false)
      .newline()
      .line("By Parkingless")
      .newline()
      .newline()
      .cut("full")
      .encode();

    usbPrintDriver.write(_encoder);
  }

  timestampToString(date: firebase.firestore.Timestamp): string {
    let serviceDate = date.toDate();
    let serviceDay =
      serviceDate.getDate() < 10
        ? "0" + serviceDate.getDate()
        : serviceDate.getDate();
    let serviceMonth =
      serviceDate.getMonth() + 1 < 10
        ? "0" + (serviceDate.getMonth() + 1)
        : serviceDate.getMonth() + 1;
    let serviceHour =
      serviceDate.getHours() < 10
        ? "0" + serviceDate.getHours()
        : serviceDate.getHours();
    let serviceMinute =
      serviceDate.getMinutes() < 10
        ? "0" + serviceDate.getMinutes()
        : serviceDate.getMinutes();
    return (
      serviceDay +
      "/" +
      serviceMonth +
      "/" +
      serviceDate.getFullYear() +
      " " +
      serviceHour +
      ":" +
      serviceMinute
    );
  }

  deleteClosedReservation(
    toDel: HourlyReservationDocument[],
    toUpd: HourlyReservationDocument[]
  ): Promise<void> {
    var batch = this.afs.firestore.batch();

    if (toDel.length > 0) {
      toDel.forEach((obj) => {
        batch.delete(
          this.hourlyReservationCollection.doc(
            this.ticketService.createTicketDocumentId(
              obj.garage.garageId,
              obj.month,
              obj.year
            )
          ).ref
        );
      });
    }
    if (toUpd.length > 0) {
      toUpd.forEach((obj) => {
        batch.update(
          this.hourlyReservationCollection.doc(
            this.ticketService.createTicketDocumentId(
              obj.garage.garageId,
              obj.month,
              obj.year
            )
          ).ref,
          {
            ticketList: obj.ticketList,
          }
        );
      });
    }
    return batch.commit();
  }
}
