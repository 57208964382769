import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormGroup } from "@angular/forms";
import * as firebase from "firebase/app";
import { firestore, img, message } from "../../../environments/environment";
import { CustomerShort } from "../interface_new/customer";
import PlaceResult = google.maps.places.PlaceResult;

import { AngularFireFunctions } from "@angular/fire/functions";
import { Address } from "../interface_new/address";
import { Garage, GarageShort } from "../interface_new/garage";
import { Vehicle } from "../interface_new/vehicle";
import { DataService } from "./data.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class CustomerService {
  constructor(
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public dataService: DataService,
    public notificationService: NotificationService
  ) {}

  generateCustomerId(): string {
    return this.afs.createId();
  }

  createEmptyCustomerShort(): CustomerShort {
    return {
      customerId: "",
      name: "",
      surname: "",
      email: "",
      displayName: "",
      photoURL: img.userImgPlaceholder,
      customerCode: "",
      fiscalCode: "",
      birthDate: null,
      mobilePhoneNumber: "",
      phoneNumber: "",
      address: null,
      birthPlace: null,
      drivingLicense: {
        licenseCode: "",
        releaseBy: null,
        releaseDate: null,
        licenseTypeList: [],
      },
      lastUpdate: null,
      incomingDate: null,
    };
  }

  getAddressFromPlaceResult(address: PlaceResult): Address {
    return {
      formatted_address: address.formatted_address,
      location: {
        lat: address.geometry.location.lat(),
        lng: address.geometry.location.lng(),
      },
    };
  }

  getCustomerAndVehicles(
    form: FormGroup,
    customerId: string,
    customerVehicleList: Vehicle[],
    garage: Garage
  ) {
    var lastUpdate = firebase.firestore.Timestamp.fromDate(new Date());
    if (form.value.birthDate != null) form.value.birthDate.setHours(16);
    if (form.value.releaseDate != null) form.value.releaseDate.setHours(16);
    var customer: CustomerShort = {
      customerId: customerId == null ? this.generateCustomerId() : customerId,
      name: form.value.name,
      surname: form.value.surname,
      email: form.value.email,
      displayName: form.value.name + " " + form.value.surname,
      photoURL: img.userImgPlaceholder,
      customerCode: form.value.customerCode,
      fiscalCode: form.value.fiscalCode,
      birthDate:
        form.value.birthDate != null
          ? firebase.firestore.Timestamp.fromDate(form.value.birthDate)
          : null,
      mobilePhoneNumber: form.value.mobilePhoneNumber,
      phoneNumber: form.value.phoneNumber,
      address:
        form.value.address != null
          ? form.value.address.geometry !== undefined
            ? this.getAddressFromPlaceResult(form.value.address)
            : form.value.address
          : null,
      birthPlace:
        form.value.birthPlace != null
          ? form.value.birthPlace.geometry !== undefined
            ? this.getAddressFromPlaceResult(form.value.birthPlace)
            : form.value.birthPlace
          : null,
      drivingLicense: {
        licenseCode: form.value.licenseCode,
        releaseBy: form.value.releaseBy,
        releaseDate:
          form.value.releaseDate != null
            ? firebase.firestore.Timestamp.fromDate(form.value.releaseDate)
            : null,
        licenseTypeList:
          form.value.licenseTypeList.length > 0 &&
          form.value.licenseTypeList[0].display !== undefined
            ? form.value.licenseTypeList.map((obj) => obj.value)
            : form.value.licenseTypeList,
      },
      lastUpdate: lastUpdate,
      incomingDate: customerId == null ? lastUpdate : null,
    };

    var vehicleList: Vehicle[] = [];

    for (let i = 0; i < form.value.vehicleList.length; i++) {
      var currVehicle = undefined;

      if (customerVehicleList != null) {
        currVehicle = customerVehicleList.find(
          (obj) => obj.plate == form.value.vehicleList[i].plate
        );
      }
      var selectedPrice = garage.priceList.find(
        (obj) => obj.name == form.value.vehicleList[i].priceName
      );
      var vehicle: Vehicle = {
        plate: form.value.vehicleList[i].plate,
        model: form.value.vehicleList[i].model,
        color: form.value.vehicleList[i].color,
        isCar: form.value.vehicleList[i].isCar.toLowerCase() == "true",
        customerId: customer.customerId,
        lastUpdate: lastUpdate,
        incomingDate:
          customerId == null || currVehicle === undefined
            ? lastUpdate
            : currVehicle.incomingDate,
        price: {
          priceId:
            selectedPrice !== undefined && selectedPrice.priceId !== undefined
              ? selectedPrice.priceId
              : "",
          lastUpdate:
            selectedPrice !== undefined && selectedPrice.lastUpdate != undefined
              ? selectedPrice.lastUpdate
              : null,
          name: form.value.vehicleList[i].priceName,
          description: form.value.vehicleList[i].priceDescription,
          price: form.value.vehicleList[i].priceCost,
          vat: form.value.vehicleList[i].priceVat,
        },
      };
      vehicleList.push(vehicle);
    }
    return {
      customer: customer,
      vehicleList: vehicleList,
    };
  }

  downloadCustomerCard(
    customer: CustomerShort,
    garage: Garage,
    vehicleList: Vehicle[],
    contractText: string
  ) {
    var garageShort: GarageShort = {
      garageId: garage.garageId,
      name: garage.name,
      description: garage.description,
      fiscalCode: garage.fiscalCode,
      phoneNumber: garage.phoneNumber,
      logo: garage.logo,
      mobilePhoneNumber: garage.mobilePhoneNumber,
      email: garage.email,
      address: garage.address,
      serviceList: [],
    };
    var data = {
      customer: customer,
      garage: garageShort,
      vehicleList: vehicleList,
      contractText: contractText,
    };

    this.aff
      .httpsCallable(firestore.function.downloadCustomerCard)(data)
      .subscribe(
        async (res) => {
          this.dataService.isDownLoading(false);
          if (res && res[0]) {
            await window.open(res[0]);
          }
        },
        (err) => {
          this.dataService.isDownLoading(false);

          this.notificationService.koNotification(
            message.customer.customerDownloadError
          );
        }
      );
  }
}
