import { Component, NgZone, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs/Subscription";
import { DataService } from "./_shared/services/data.service";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  private _router: Subscription;
  isloading: boolean = true;
  isDownloading: boolean = false;
  url: any;
  constructor(
    private router: Router,
    public dataService: DataService,
    public route: ActivatedRoute,
    public _ngZone: NgZone
  ) {}

  ngOnInit() {
    if (
      (document.location.href.endsWith("pages/login") ||
        document.location.href.endsWith("pages/register") ||
        document.location.href.endsWith("pages/verify-email-address")) &&
      this.isloading
    )
      this.dataService.isLoading(false);
    this.dataService.loader.subscribe((loader) => {
      this._ngZone.run(() => {
        this.isloading = loader;
      });
    });
    this.dataService.downloader.subscribe((downloader) => {
      this._ngZone.run(() => {
        this.isDownloading = downloader;
      });
    });
    this._router = this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop =
          document.getElementsByClassName("modal-backdrop")[0];
        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });
  }
}
