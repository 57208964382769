import { Injectable, NgZone } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { url, global } from "src/environments/environment";
import { Settings } from "../interface_new/setting";
import { DataService } from "../services/data.service";
import { SettingsService } from "../services/settings.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public dataService: DataService,
    public settingService: SettingsService,
    public _ngZone: NgZone
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.settingService
      .getSettings(global.settingsVersion)
      .valueChanges()
      .subscribe((settings) => {
        if (settings != null) {
          var s = settings as Settings;
          this.dataService.setSettings(s);
          if (
            s.garageApp.web !== undefined &&
            s.garageApp.web.inMaintenance.value
          ) {
            this.dataService.isLoading(false);
            this._ngZone.run(() => {
              this.router.navigate([url.inMaintenance.path]);
            });
          } else {
            this.afAuth.user.subscribe((data) => {
              if (data == null) {
                this.dataService.isLoading(false);
                this._ngZone.run(() => {
                  this.router.navigate([url.login.path]);
                });
              }
            });
          }
        }
      });

    return true;
  }
}
