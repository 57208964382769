import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http"; // <-- import `HttpClient`
import { Injectable } from "@angular/core";
import { apiUrl, environment, message } from "src/environments/environment";
import { DataService } from "./data.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class PaypalService {
  basicAuth: string =
    "Basic " +
    btoa(
      environment.paypalConfig.clientId + ":" + environment.paypalConfig.secret
    );
  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private notificationService: NotificationService
  ) {}

  getSubsciption(subcriptionId) {
    var headers = new HttpHeaders();
    headers = headers.set("Authorization", this.basicAuth);
    headers = headers.set("Content-Type", "application/json");
    this.http
      .get(apiUrl.payPalSubscription + subcriptionId, { headers: headers })
      .subscribe({
        next: (response: any) => {
          this.dataService.setSubscription(response);
        },
        error: (error: any) => {
          this.notificationService.koNotification(
            message.paypalAPI.paypalSubscriptioneError
          );
        },
        complete: () => {},
      });
  }

  getSubscriptionPayment(subcriptionId, startDate, endDate): any {
    var headers = new HttpHeaders();
    headers = headers.set("Authorization", this.basicAuth);
    headers = headers.set("Content-Type", "application/json");
    var query = new HttpParams();
    query = query.set("start_time", startDate);
    query = query.set("end_time", endDate);
    return this.http
      .get(apiUrl.payPalSubscription + subcriptionId + "/transactions", {
        headers: headers,
        params: query,
      })
      .subscribe({
        next: (response: any) => {
          this.dataService.setSubscriptionPayment(response);
        },
        error: (error: any) => {
          this.notificationService.koNotification(
            message.paypalAPI.paypalSubscriptioneError
          );
        },
        complete: () => {},
      });
  }

  suspendeSubscription(subcriptionId, reason): any {
    var headers = new HttpHeaders();
    headers = headers.set("Authorization", this.basicAuth);
    headers = headers.set("Content-Type", "application/json");
    return this.http.post(
      apiUrl.payPalSubscription + subcriptionId + "/cancel",
      { reason: reason },
      { headers: headers }
    );
  }
}
