import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";
import { global, role, url } from "src/environments/environment";
import { User } from "../_shared/interface_new/user";
import { AuthService } from "../_shared/services/auth.service";
import { DataService } from "../_shared/services/data.service";
import { RouteInfo } from "../_shared/interface_new/navbar";
declare const $: any;

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  ps: any;
  userProfilePath: string = url.userProfile.path;
  myGaragePath: string = url.garageAdmin.path;
  supportPath: string = url.support.path;
  currentUser: User = null;

  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public router: Router
  ) {}

  ngOnInit() {
    this.dataService.currentUser.subscribe((currentUser) => {
      if (currentUser != null) {
        this.currentUser = currentUser;
        this.dataService.garageRole.subscribe((data) => {
          if (data !== undefined && data != null && data.length > 0) {
            this.menuItems = [];
            for (let i = 0; i < data.length; i++) {
              var menuItem = {
                path: "/parking/" + data[i].garage.garageId,
                title:
                  data[i].garage.name.length > 18
                    ? data[i].garage.name.substring(0, 18)
                    : data[i].garage.name,
                type: "sub",
                icontype: "local_parking",
                collapse: data[i].garage.garageId,
                children:
                  data[i].role != role.employee
                    ? global.garageSubPage
                    : global.garageEmployeeSubPage,
              };
              if (
                data[i].garage.garageId != null &&
                data[i].garage.garageId != ""
              )
                this.menuItems.push(menuItem);
            }
          } else {
            this.menuItems = [];
          }
        });

        this.menuItems = ROUTES.filter((menuItem) => menuItem);
      }
    });

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>(
        document.querySelector(".sidebar .sidebar-wrapper")
      );
      this.ps = new PerfectScrollbar(elemSidebar);
    }
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  logOut() {
    this.authService
      .SignOut()
      .finally(() => this.router.navigate([url.login.path]));
  }
}
