<div class="logo">
  <a class="simple-text logo-mini">
    <div class="logo-img">
      <img src="/assets/img/logo_negativo.svg" />
    </div>
  </a>
  <a href="/garage/admin" class="simple-text logo-normal">
    <div class="">
      <img style="height: 100%" src="/assets/img/logo_esteso_negativo.svg" />
    </div>
  </a>
</div>

<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <img
        *ngIf="!currentUser || !currentUser.photoURL"
        src="/assets/img/placeholder.jpg"
      />
      <img *ngIf="currentUser" [src]="currentUser.photoURL" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#profileCollapse" class="collapsed">
        <span *ngIf="currentUser">
          {{ currentUser.displayName }}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="profileCollapse">
        <ul class="nav">
          <li routerLinkActive="active" class="nav-item">
            <a [routerLink]="['/' + userProfilePath]" class="nav-link">
              <span class="sidebar-mini">MP</span>
              <span class="sidebar-normal">My Profile</span>
            </a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a [routerLink]="['/' + myGaragePath]" class="nav-link">
              <span class="sidebar-mini">MG</span>
              <span class="sidebar-normal">My Garage</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <!--  <li class="nav-item dropdown">
                  <a class="nav-link" href="#pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                      <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Mike John responded to your email</a>
                    <a class="dropdown-item" href="#">You have 5 new tasks</a>
                    <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                    <a class="dropdown-item" href="#">Another Notification</a>
                    <a class="dropdown-item" href="#">Another One</a>
                  </div>
                </li> -->
    </ul>
  </div>
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuitem of menuItems; let i = index"
      class="nav-item"
    >
      <!--If is a single link-->
      <a
        [routerLink]="[menuitem.path]"
        *ngIf="menuitem.type === 'link'"
        class="nav-link"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}</p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        href="#List-{{ i }}"
        *ngIf="menuitem.type === 'sub'"
        (click)="updatePS()"
        class="nav-link"
      >
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>{{ menuitem.title }}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="List-{{ i }}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li
            routerLinkActive="active"
            *ngFor="let childitem of menuitem.children"
            class="nav-item"
          >
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini"
                ><i class="material-icons">{{ childitem.ab }}</i></span
              >
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/' + supportPath]" class="nav-link">
        <i class="material-icons">help_outline</i>
        <p>Contattaci</p>
      </a>
    </li>
    <li class="nav-item">
      <a href="#" (click)="logOut()" class="nav-link">
        <i class="material-icons">exit_to_app</i>
        <p>Esci</p>
      </a>
    </li>
  </ul>
</div>
