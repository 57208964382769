import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from "@angular/fire/firestore";
import { Borders, Fill, Workbook, Worksheet } from "exceljs";
import * as fs from "file-saver";
import _moment, { Moment, default as _rollupMoment } from "moment";
import { firestore, global, xls } from "../../../environments/environment";
import { Garage } from "../interface_new/garage";
import { CustomerPayment, MonthlyPayment } from "../interface_new/payment";
import { TicketService } from "./ticket.service";
const moment = _rollupMoment || _moment;

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  paymentCollection: AngularFirestoreCollection;
  archivedPaymentCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public ticketService: TicketService
  ) {
    this.paymentCollection = this.afs.collection<MonthlyPayment>(
      firestore.collection.payment
    );
    this.archivedPaymentCollection = this.afs.collection<MonthlyPayment>(
      firestore.collection.paymentArchived
    );
  }

  resetDocument(payment): Promise<void> {
    var batch = this.afs.firestore.batch();
    batch.delete(
      this.archivedPaymentCollection.doc(
        this.ticketService.createTicketDocumentId(
          payment.garageId,
          payment.month,
          payment.year
        )
      ).ref
    );
    batch.set(
      this.paymentCollection.doc(
        this.ticketService.createTicketDocumentId(
          payment.garageId,
          payment.month,
          payment.year
        )
      ).ref,
      payment
    );
    return batch.commit();
  }

  getMonthlyPaymentDocumentList(garageId: string[]): DocumentData {
    return this.paymentCollection.ref.where("garageId", "in", garageId);
  }

  getArchivedMonthlyPaymentDocumentList(garageId: string): DocumentData {
    return this.archivedPaymentCollection.ref.where("garageId", "==", garageId);
  }

  buildDailyReportTotal(
    worksheet: Worksheet,
    startIndex: number,
    totalAmount: number,
    totalFee: number,
    totalService: number,
    totalVat: number
  ) {
    worksheet.mergeCells("D" + startIndex + ":E" + startIndex);
    var totalLabelCell = worksheet.getCell("D" + startIndex);
    totalLabelCell.value = "Totale";
    totalLabelCell.border = xls.dailyReportHeaderTableBorder as Borders;
    totalLabelCell.font = xls.customerCodeFont;

    var feeTotalCell = worksheet.getCell("F" + startIndex);
    feeTotalCell.value = totalFee;
    feeTotalCell.border = xls.dailyReportHeaderTableBorder as Borders;
    feeTotalCell.font = xls.dailyReporTableFont;

    var serviceTotalCell = worksheet.getCell("G" + startIndex);
    serviceTotalCell.value = totalService;
    serviceTotalCell.border = xls.dailyReportHeaderTableBorder as Borders;
    serviceTotalCell.font = xls.dailyReporTableFont;

    var taxTotalCell = worksheet.getCell("H" + startIndex);
    taxTotalCell.value = totalVat;
    taxTotalCell.border = xls.dailyReportHeaderTableBorder as Borders;
    taxTotalCell.font = xls.dailyReporTableFont;

    var totalCell = worksheet.getCell("I" + startIndex);
    totalCell.value = totalAmount;
    totalCell.border = xls.dailyReportHeaderTableBorder as Borders;
    totalCell.font = xls.dailyReporTableFont;
  }

  buildDailyReportTable(
    worksheet: Worksheet,
    startIndex: number,
    isHeader: boolean,
    payment: CustomerPayment
  ) {
    worksheet.mergeCells("A" + startIndex + ":B" + startIndex);
    var headerTableCustomer = worksheet.getCell("A" + startIndex);
    headerTableCustomer.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableCustomer.fill = xls.dailyReportHeaderTableBackground as Fill;
    headerTableCustomer.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;
    var description = "";
    var customer = "";
    if (payment != null) {
      if (payment.ticketType == global.ticketTypeString.service) {
        description =
          "Servizi " +
          this.ticketService.getMonthString(payment.feeMonth) +
          " " +
          payment.feeYear;
        customer = payment.customer.displayName;
      } else if (payment.ticketType == global.ticketTypeString.fee) {
        description =
          "Canone " +
          this.ticketService.getMonthString(payment.feeMonth) +
          " " +
          payment.feeYear;
        customer = payment.customer.displayName;
      } else if (payment.ticketType == global.ticketTypeString.convention) {
        description = payment.description;
        customer = payment.customer.email;
      } else {
        customer = payment.vehicle.plate
        description = "Sosta oraria";
      }
    }
    headerTableCustomer.value = isHeader ? "Cliente" : customer;

    worksheet.mergeCells("C" + startIndex + ":D" + startIndex);
    var headerTableDescription = worksheet.getCell("C" + startIndex);
    headerTableDescription.value = isHeader
      ? "Descrizione Pagamento"
      : description;
    headerTableDescription.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableDescription.fill =
        xls.dailyReportHeaderTableBackground as Fill;
    headerTableDescription.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;

    var headerTablePaymentType = worksheet.getCell("E" + startIndex);
    headerTablePaymentType.value = isHeader
    ? "Tipo Pagamento"
    : this.ticketService.paymentTypeLabel(payment.paymentType);
    headerTablePaymentType.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
    headerTablePaymentType.fill =
        xls.dailyReportHeaderTableBackground as Fill;
        headerTablePaymentType.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;

    var headerTableFee = worksheet.getCell("F" + startIndex);
    headerTableFee.value = isHeader
      ? "Canone"
      : payment.total - payment.servicePrice;
    headerTableFee.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableFee.fill = xls.dailyReportHeaderTableBackground as Fill;
    headerTableFee.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;

    var headerTableService = worksheet.getCell("G" + startIndex);
    headerTableService.value = isHeader ? "Servizi" : payment.servicePrice;
    headerTableService.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableService.fill = xls.dailyReportHeaderTableBackground as Fill;
    headerTableService.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;

    var headerTableVat = worksheet.getCell("H" + startIndex);
    headerTableVat.value = isHeader ? "Iva" : payment.taxAmount;
    headerTableVat.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableVat.fill = xls.dailyReportHeaderTableBackground as Fill;
    headerTableVat.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;

    var headerTableTot = worksheet.getCell("I" + startIndex);
    headerTableTot.value = isHeader ? "Totale" : payment.total;
    headerTableTot.border = xls.dailyReportHeaderTableBorder as Borders;
    if (isHeader)
      headerTableTot.fill = xls.dailyReportHeaderTableBackground as Fill;
    headerTableTot.font = isHeader
      ? xls.dailyReportHeaderTableFont
      : xls.dailyReporTableFont;
  }

  buildDailyReportSheet(
    worksheet: Worksheet,
    garage: Garage,
    paymentList: CustomerPayment[],
    day: Moment,
    dateLabel: string,
    isDaily: boolean
  ) {
    var totalAmount = 0;
    var totalFee = 0;
    var totalService = 0;
    var totalVat = 0;
    var startIndex = 1;
    startIndex = this.ticketService.buildGarageExcelHeader(
      worksheet,
      startIndex,
      garage
    );
    startIndex += 2;
    let dailyReportCel = worksheet.getCell("A" + startIndex);
    dailyReportCel.value = isDaily
      ? "Conto Cassa : " + dateLabel
      : "Incasso : " + dateLabel;
    dailyReportCel.font = xls.customerCodeFont;
    startIndex += 2;
    this.buildDailyReportTable(worksheet, startIndex, true, null);
    startIndex += 1;
    paymentList.forEach((obj) => {
      this.buildDailyReportTable(worksheet, startIndex, false, obj);
      totalAmount += obj.total;
      totalFee += obj.total - obj.servicePrice;
      totalService += obj.servicePrice;
      totalVat += obj.taxAmount;
      startIndex += 1;
    });
    startIndex += 1;
    this.buildDailyReportTotal(
      worksheet,
      startIndex,
      totalAmount,
      totalFee,
      totalService,
      totalVat
    );
  }

  exportDailyReport(
    garageTicket: CustomerPayment[],
    day: Moment,
    garage: Garage
  ): void {
    let workbook = new Workbook();
    var dayLabel = day.date() < 10 ? "0" + day.date() : day.date();
    var dateLabel =
      dayLabel +
      "-" +
      this.ticketService.getMonthString(day.month() + 1) +
      "-" +
      day.year();
    let worksheet = workbook.addWorksheet(dateLabel);
    this.buildDailyReportSheet(
      worksheet,
      garage,
      garageTicket,
      day,
      dateLabel,
      true
    );
    var feeList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.fee
    );
    if (feeList !== undefined && feeList.length > 0) {
      let feeWorksheet = workbook.addWorksheet("Abbonamenti");
      this.buildDailyReportSheet(
        feeWorksheet,
        garage,
        feeList,
        day,
        dateLabel,
        true
      );
    }
    var serviceList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.service
    );
    if (serviceList !== undefined && serviceList.length > 0) {
      let serviceWorksheet = workbook.addWorksheet("Servizi");
      this.buildDailyReportSheet(
        serviceWorksheet,
        garage,
        serviceList,
        day,
        dateLabel,
        true
      );
    }
    var hourList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.hour
    );
    if (hourList !== undefined && hourList.length > 0) {
      let hourWorksheet = workbook.addWorksheet("Sosta Oraria");
      this.buildDailyReportSheet(
        hourWorksheet,
        garage,
        hourList,
        day,
        dateLabel,
        true
      );
    }
    var conventionList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.convention
    );
    if (conventionList !== undefined && conventionList.length > 0) {
      let hourWorksheet = workbook.addWorksheet("Convenzioni");
      this.buildDailyReportSheet(
        hourWorksheet,
        garage,
        conventionList,
        day,
        dateLabel,
        true
      );
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, dateLabel + ".xlsx");
    });
  }

  exportMonthlyReport(
    garageTicket: CustomerPayment[],
    day: Moment,
    garage: Garage
  ) {
    let workbook = new Workbook();
    var dateLabel =
      this.ticketService.getMonthString(day.month() + 1) + "-" + day.year();
    let worksheet = workbook.addWorksheet(dateLabel);

    this.buildDailyReportSheet(
      worksheet,
      garage,
      garageTicket,
      day,
      dateLabel,
      false
    );
    var feeList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.fee
    );
    if (feeList !== undefined && feeList.length > 0) {
      let feeWorksheet = workbook.addWorksheet("Abbonamenti");
      this.buildDailyReportSheet(
        feeWorksheet,
        garage,
        feeList,
        day,
        dateLabel,
        true
      );
    }
    var serviceList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.service
    );
    if (serviceList !== undefined && serviceList.length > 0) {
      let serviceWorksheet = workbook.addWorksheet("Servizi");
      this.buildDailyReportSheet(
        serviceWorksheet,
        garage,
        serviceList,
        day,
        dateLabel,
        true
      );
    }
    var hourList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.hour
    );
    if (hourList !== undefined && hourList.length > 0) {
      let hourWorksheet = workbook.addWorksheet("Sosta Oraria");
      this.buildDailyReportSheet(
        hourWorksheet,
        garage,
        hourList,
        day,
        dateLabel,
        true
      );
    }
    var conventionList = garageTicket.filter(
      (obj) => obj.ticketType == global.ticketTypeString.convention
    );
    if (conventionList !== undefined && conventionList.length > 0) {
      let hourWorksheet = workbook.addWorksheet("Convenzioni");
      this.buildDailyReportSheet(
        hourWorksheet,
        garage,
        conventionList,
        day,
        dateLabel,
        true
      );
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, dateLabel + ".xlsx");
    });
  }
}
