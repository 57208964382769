import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import * as firebase from "firebase/app";
import { firestore } from "../../../environments/environment";
import { Garage, GarageMin } from "../interface_new/garage";
import { Convention, Partner } from "../interface_new/partner";
import { CustomerService } from "./customer.service";

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  partnerCollection: AngularFirestoreCollection;
  garageCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    private customerService: CustomerService
  ) {
    this.partnerCollection = afs.collection<Partner>(
      firestore.collection.partner
    );
    this.garageCollection = afs.collection<Garage>(firestore.collection.garage);
  }

  generatePartnerId(): string {
    return this.afs.createId();
  }

  mapPartnerFormToObj(
    partnerForm: any,
    garage: GarageMin,
    adminList: string[]
  ): Partner {
    if (partnerForm.address != null) {
      partnerForm.address = this.customerService.getAddressFromPlaceResult(
        partnerForm.address
      );
    }
    var result = partnerForm as Partner;
    result.partnerId = this.generatePartnerId();
    result.name = result.name == null ? "" : result.name;
    result.surname = result.surname == null ? "" : result.surname;
    result.fiscalCode = result.fiscalCode == null ? "" : result.fiscalCode;
    result.phoneNumber = result.phoneNumber == null ? "" : result.phoneNumber;
    result.email = result.email.toLowerCase();
    result.garage = garage;
    result.activeConventionList = [];
    result.closedConventionList = [];
    result.adminList = adminList;
    result.lastUpdate = firebase.firestore.Timestamp.now();
    return result;
  }

  createNewPartner(partner: Partner): Promise<void> {
    var batch = this.afs.firestore.batch();

    batch.set(this.partnerCollection.doc(partner.partnerId).ref, partner);
    batch.update(this.garageCollection.doc(partner.garage.garageId).ref, {
      partnerList: firebase.firestore.FieldValue.arrayUnion(partner.email),
    });

    return batch.commit();
  }

  getPartnerlist(garageId: string[]): DocumentData {
    return this.partnerCollection.ref.where("garage.garageId", "in", garageId);
  }

  updatePartnerInfo(partnerId: string, partial: any): Promise<void> {
    return this.partnerCollection.doc(partnerId).update(partial);
  }

  updatePartnerActiveConvention(
    partnerId: string,
    activeConventionList: Convention[]
  ): Promise<void> {
    return this.partnerCollection
      .doc(partnerId)
      .update({ activeConventionList: activeConventionList });
  }
  updatePartnerClosedConvention(
    partnerId: string,
    closedConventionList: Convention[]
  ): Promise<void> {
    return this.partnerCollection
      .doc(partnerId)
      .update({ closedConventionList: closedConventionList });
  }
  updatePartnerActiveAndClosedConvention(
    partnerId: string,
    activeConventionList: Convention[],
    closedConventionList: Convention[]
  ): Promise<void> {
    return this.partnerCollection.doc(partnerId).update({
      activeConventionList: activeConventionList,
      closedConventionList: closedConventionList,
    });
  }

  deletePartner(partner: Partner): Promise<void> {
    var batch = this.afs.firestore.batch();

    batch.delete(this.partnerCollection.doc(partner.partnerId).ref);

    batch.update(this.garageCollection.doc(partner.garage.garageId).ref, {
      partnerList: firebase.firestore.FieldValue.arrayRemove(partner.email),
    });

    return batch.commit();
  }
}
