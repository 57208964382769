import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { firestore } from "src/environments/environment";
import { Settings } from "../interface_new/setting";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  settingsCollection: AngularFirestoreCollection;

  constructor(public afs: AngularFirestore, public dataService: DataService) {
    this.settingsCollection = this.afs.collection<Settings>(
      firestore.collection.settings
    );
  }

  getSettings(settingsVersion) {
    return this.settingsCollection.doc(settingsVersion);
  }
}
