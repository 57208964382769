<nav
  class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute"
  color-on-scroll="500"
>
  <div class="container">
    <div class="navbar-wrapper">
      <div class="logo-img">
        <img style="height: 40px" src="/assets/img/logo_esteso_negativo.svg" />
      </div>
    </div>
    <button
      mat-button
      class="navbar-toggler"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div
      *ngIf="!underMaintenance"
      class="collapse navbar-collapse justify-content-end"
    >
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/pages/register']">
            <i class="material-icons">person_add</i> Registrati
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/pages/login']">
            <i class="material-icons">fingerprint</i> Login
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
