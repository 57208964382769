import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentData,
} from "@angular/fire/firestore";
import { firestore } from "src/environments/environment";
import { Notification } from "../interface_new/notification";

declare const $: any;

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  notificationCollection: AngularFirestoreCollection;

  constructor(public afs: AngularFirestore) {
    this.notificationCollection = afs.collection<Notification>(
      firestore.collection.notification
    );
  }

  koNotification(message) {
    this.showNotification("bottom", "right", message, "danger", "error");
  }

  okNotification(message) {
    this.showNotification("bottom", "right", message, "success", "done");
  }

  private showNotification(
    from: any,
    align: any,
    message: string,
    type: string,
    icon: string
  ): void {
    $.notify(
      {
        icon: icon,
        message: message,
      },
      {
        type: type,
        timer: 1500,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">' +
          icon +
          "</i> " +
          '<span data-notify="title"></span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  getNotificationDocument(id) {
    return this.notificationCollection.doc(id);
  }

  getNotificationDocumentList(garageList: string[]): DocumentData {
    return this.notificationCollection.ref.where("garageId", "in", garageList);
  }

  updateNotificationService(garageId, notificationList): Promise<void> {
    return this.notificationCollection
      .doc(garageId)
      .update({ notificationList: notificationList });
  }
}
