// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebaseConfig: {
   apiKey: "AIzaSyBFYjw1QLKdAHSRCPfecEubHCWMi6SHCAc",
    authDomain: "parkingless-dev-6a8a1.firebaseapp.com",
    projectId: "parkingless-dev-6a8a1",
    storageBucket: "parkingless-dev-6a8a1.appspot.com",
    messagingSenderId: "535312796497",
    appId: "1:535312796497:web:7525d5f48f7faa239d6170",
    
     /* apiKey: "AIzaSyA-_J5JBNbfolwWybMJl1008wRNeWBrRxM",
    authDomain: "parkingless-578ed.firebaseapp.com",
    projectId: "parkingless",
    storageBucket: "parkingless.appspot.com",
    messagingSenderId: "946929600030",
    appId: "1:946929600030:web:cd76d39d4a3c14e046900f",
     */
  },
  paypalConfig: {
    clientId:
      "AeBrF9hPQovyHXQv5z00YdgJPJHdEXibImt50hbDwlUm89hLZDzRDupRIOzKkJN7sr156sOFU4xFn92z",
    secret:
      "ELTKlckeKb9o-c4aHh7c6-Wda8JCQaxhEw1MysfWT0jqPxMZ6rFku_cePi0px7ivxHYxa1GWjbbO0zl3",
    accessToken:
      "aaccess_token$sandbox$mqffcgh3gz29bz9f$28ef8ba8e7f7f9fd823cee25b5210802",
    currency: "EUR",
    intent: "CAPTURE",
  },
  googleMapsConfig: {
    apiKey: "AIzaSyAkfKf4Fx5T4rGNHrgaHq7fwo_XGi8-MEI",
    libraries: ["places", "visualization"],
  },
};

export const firestore = {
  collection: {
    user: "user",
    role: "roleCollection",
    licenseType: "licenseType",
    license: "license",
    garage: "garage",
    garageDeleted: "garageDeleted",
    customer: "customer",
    ticket: "ticket",
    ticketDeleted: "ticketDeleted",
    ticketArchived: "archivedTicket",
    payment: "payment",
    paymentDeleted: "paymentDeleted",
    paymentArchived: "archivedPayment",
    hourlyReservation: "hourlyReservation",
    hourlyTicket: "hourlyTicket",
    notification: "notification",
    settings: "settings",
    support: "support",
    partner: "partner",
  },
  storage: {
    userImgFolder: "/user-img/",
    garageImgFolder: "/garage-logo/",
    termsAndContitionFile:
      "https://firebasestorage.googleapis.com/v0/b/parkingless.appspot.com/o/docs%2FTermini_e_Condizioni.pdf?alt=media&token=a6e15085-caad-4664-b074-287213582d0a",
  },
  function: {
    downloadTicket: "downloadTicket",
    downloadCustomerCard: "downloadCustomerCard",
    sendTicketToCustomer: "sendTicketToCustomer",
    sendHourlyParking: "sendHourlyParking",
    downloadToDoServiceList: "downloadToDoServiceList",
    downloadInvoice: "downloadInvoice",
    sendSupportMessage: "sendSupportMessageFunction",
  },
};

export const role = {
  owner: "owner",
  admin: "admin",
  employee: "employee",
  customer: "customer",
};

export const img = {
  userImgPlaceholder: "assets/img/placeholder.jpg",
  garageImgPlaceholder: "assets/img/garage-placeholder.png",
};

export const richTextSetting = {
  items: [
    "Undo",
    "Redo",
    "|",
    "Bold",
    "Italic",
    "Underline",
    "StrikeThrough",
    "|",
    "FontName",
    "FontSize",
    "FontColor",
    "BackgroundColor",
    "|",
    "SubScript",
    "SuperScript",
    "|",
    "LowerCase",
    "UpperCase",
    "|",
    "Formats",
    "Alignments",
    "|",
    "OrderedList",
    "UnorderedList",
    "|",
    "Indent",
    "Outdent",
    "|",
    "CreateLink",
    "Image",
    "|",
    "ClearFormat",
    "Print",
    "SourceCode",
    "|",
    "FullScreen",
  ],
};

export const table = {
  priceListHeaderRow: ["name", "description", "price", "vat", "action"],
  conventionPriceListHeaderRow: ["name", "price", "vat", "action"],
  hourlypriceListHeaderRow: [
    "name",
    "price",
    "tollerance",
    "vat",
    "isTimeCalculation",
    "action",
  ],
  printerListHeaderRow: ["device", "active", "action"],
  serviceListHeaderRow: [
    "serviceName",
    "description",
    "price",
    "vat",
    "category",
    "action",
  ],
  customerListHeaderRow: [
    "customerCode",
    "name",
    "surname",
    "email",
    "mobilePhoneNumber",
    "action",
  ],
  customerListBillingHeaderRow: ["customerCode", "name", "surname", "action"],
  vheicleListHeaderRow: [
    "isCar",
    "plate",
    "model",
    "color",
    "price",
    "amount",
    "action",
  ],
  openTicketListHeaderRow: [
    "period",
    "totalTicket",
    "paidTicket",
    "feeTicekt",
    "paidFeeTicket",
    "action",
  ],
  monthlyPaymentHeaderRow: [
    "customerCode",
    "displayName",
    "paymentDate",
    "description",
    "total",
  ],
  userPaymentHeaderRow: ["Data", "Descrizione", "Importo", ""],
  partnerListHeaderRow: ["email", "action"],
};

export const xls = {
  excelFileType:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  excelFileExtension: ".xlsx",
  titleFont: {
    name: "Calibri",
    size: 16,
    bold: true,
  },
  titleAlignment: { vertical: "middle", horizontal: "center" },
  customerAlignment: { vertical: "middle", horizontal: "right" },
  subTitleFont: {
    name: "Calibri",
    size: 14,
  },
  contactFont: {
    name: "Calibri",
    size: 13,
  },
  customerCodeFont: {
    name: "Calibri",
    size: 13,
    bold: true,
  },
  amountFont: {
    name: "Calibri",
    size: 14,
    bold: true,
  },
  dailyReportHeaderTableFont: {
    name: "Calibri",
    size: 14,
    bold: true,
    color: { argb: "FFFFFF" },
  },
  dailyReporTableFont: {
    name: "Calibri",
    size: 13,
    bold: false,
  },
  dailyReportHeaderTableBorder: {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
    diagonal: null,
  },
  dailyReportHeaderTableBackground: {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "00bcd4" },
  },
};

export const dateFormat = {
  parse: {
    dateInput: "MM-YYYY",
  },
  display: {
    dateInput: "MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const global = {
  hourlyTicketPriceType: [
    {
      value: "day",
      label: "Giornaliero",
    },
    {
      value: "half",
      label: "Mezza Giornata",
    },
    {
      value: "hour",
      label: "Orario",
    },
    {
      value: "custom",
      label: "Orario Personalizzato",
    },
  ],
  ticketTypeString: {
    fee: "fee",
    service: "service",
    hour: "hour",
    convention: "convention",
  },
  ticketTypeSelect: [
    {
      value: "",
      label: "Tutti",
    },
    {
      value: "fee",
      label: "Abbonamenti",
    },
    {
      value: "service",
      label: "Servizi",
    },
    {
      value: "hour",
      label: "Sosta Oraria",
    },
    {
      value: "convention",
      label: "Convenzioni",
    },
  ],
  contractPlaceholder: "Inserisci qui il tuo contratto",
  contactPlaceholder: "Inserisci qui la tua richiesta di Supporto",

  adminListTagPlaceholder: "+ Email Utenza Admin",
  employeeListTagPlaceholder: "+ Email Dipendente",
  color: {
    green: "#43a047",
    red: "#ef5350",
    yellow: "#fbc02d",
    blueSky: "#00bcd4",
  },
  garageSubPage: [
    { path: "dashboard", title: "Dashboard", ab: "dashboard" },
    { path: "settings", title: "Impostazioni", ab: "settings" },
    { path: "customer-registry", title: "Anagrafica Clienti", ab: "group" },
    { path: "add-customer", title: "Aggiungi Cliente", ab: "person_add" },
    { path: "ticket", title: "Ricevute", ab: "receipt_long" },
    { path: "payment", title: "Pagamenti", ab: "euro" },
    { path: "service", title: "Agenda Servizi", ab: "calendar_month" },
    { path: "hourly-parking", title: "Sosta Oraria", ab: "schedule" },
    { path: "partner", title: "Convenzioni", ab: "handshake" },
    { path: "history", title: "Storico", ab: "manage_search" },
  ],
  garageEmployeeSubPage: [
    { path: "service", title: "Agenda Servizi", ab: "calendar_month" },
    { path: "hourly-parking", title: "Sosta Oraria", ab: "schedule" },
  ],
  googleProvider: "google.com",
  rateList: ["semestrale", "annuale"],
  serviceCategoryList: [
    { value: "engineering", label: "Nessuna Categoria" },
    { value: "book_online", label: "Prenotazione" },
    { value: "local_car_wash", label: "Lavaggio" },
    { value: "grading", label: "Revisione Veicolo" },
    { value: "medical_services", label: "Tagliando Veicolo" },
    { value: "car_repair", label: "Riparazione" },
    { value: "electrical_services", label: "Ricarica Veicolo" },
  ],
  ticketChoiceList: [
    { value: true, label: "Seleziona Ricevuta Esistente" },
    { value: false, label: "Crea una nuova Ricevuta" },
  ],
  ticketType: [
    {
      label: "Abbonamento",
      value: false,
    },
    {
      label: "Servizio",
      value: true,
    },
  ],

  paymentType: {
    cash: {
      value: "cash",
      label: "Contanti",
      icon: "euro",
    },
    pos: {
      value: "pos",
      label: "POS",
      icon: "payment",
    },
    check: {
      value: "check",
      label: "Assegno",
      icon: "money",
    },
    transaction: {
      value: "transaction",
      label: "Bonifico",
      icon: "account_balance",
    },
  },

  month: {
    1: {
      label: "Gennaio",
      short: "Gen",
    },
    2: {
      label: "Febbraio",
      short: "Feb",
    },
    3: {
      label: "Marzo",
      short: "Mar",
    },
    4: {
      label: "Aprile",
      short: "Apr",
    },
    5: {
      label: "Maggio",
      short: "Mag",
    },
    6: {
      label: "Giugno",
      short: "Giu",
    },
    7: {
      label: "Luglio",
      short: "Lug",
    },
    8: {
      label: "Agosto",
      short: "Ago",
    },
    9: {
      label: "Settembre",
      short: "Set",
    },
    10: {
      label: "Ottobre",
      short: "Ott",
    },
    11: {
      label: "Novembre",
      short: "Nov",
    },
    12: {
      label: "Dicembre",
      short: "Dic",
    },
  },
  toDoServiceStatus: {
    waitingForGarageApproval: "waitingForGarageApproval",
    waitingForCustomerApproval: "waitingForCustomerApproval",
    approved: "approved",
    rejectedfromGarage: "rejectedFromGarage",
    rejectedfromCustomer: "rejectedFromCustomer",
    closed: "closed",
  },
  settingsVersion: "v1",
  login: {
    invalidLoginTitle: "Login Fallita",
    invalidEmailErrorCode: "auth/invalid-email",
    invalidEmailErrorMessage: "Inserisci un indirizzo email valido",
    wrongPasswordErrorCode: "auth/wrong-password",
    wrongPasswordErrorMessage: "Credenziali non Valide",
    invalidRegistrationTitle: "Registrazione Fallita",
    sentRequestTitle: "Richiesta Inviata",
    sentRequestMessage: "Controlla la tua Email per resettare la Password",
    resetPasswordErrorTitle: "Reset Password Fallito",
    resetPasswordInvalidEmailMessage: "Inserisci un indirizzo email valido",
    userNotFoundErrorCode: "auth/user-not-found",
    userNotFoundErrorMessage: "Utente non trovato a sistema",
  },
};

export const url = {
  inMaintenance: {
    path: "pages/maintenance",
    title: "Sito in Manutenzione",
  },
  login: {
    path: "pages/login",
    title: "Accedi a ParkingLess",
  },
  verifyEmail: {
    path: "pages/verify-email-address",
    title: "Verifica la tua Email",
  },
  registration: {
    path: "/pages/register",
    title: "Registrati a ParkingLess",
  },
  buyLicense: {
    path: "buy-your-license",
    title: "Acquista Una Licenza",
  },
  garageAdmin: {
    path: "garage/admin",
    title: "Garage Admin",
  },
  renewLicense: {
    path: "renew-license",
    title: "Rinnova Licenza",
  },
  waitrenewLicense: {
    path: "wait-renew-license",
    title: "Rinnova Licenza",
  },
  userProfile: {
    path: "user/profile",
    title: "Profilo Utente",
  },
  support: {
    path: "support",
    title: "Contattaci",
  },
};

export const model = {
  garage: {
    emptyName: "Nuovo Garage Disponibile",
    emptyDesc: "Aggiungi un nuovo Garage",
  },
};

export const message = {
  partner: {
    addGPartnerSuccess: "Convenzionato creato correttamente",
    addPartnerError: "Errore nel salvataggio del Convenzionato",
    modifiyPartnerSuccess: "Convenzionato modificato correttamente",
    alreadyExist: "Email Convenzionato già presente a sistema",
    addConventionSuccess: "Convenzione Aggiunta correttamente",
    addConventionError: "Errore nel salvataggio della convenzione",
    modifyConventionSuccess: "Convenzione Modificata correttamente",
    deletePartnerError:
      "Impossibile Eliminare Convenzionati con Convenzioni Attive",
    deletePartnerSuccess: "Convenzionato eiminato con successo",
    deletePartneFail: "Errore nella cancellazione del Convenzionato",
  },

  garage: {
    deleteOwnerError:
      "Il proprietario della licenza non può essere rimosso dalla lista degli admin",
    adminListMaxError: "Hai raggiunto il numero massimo di Utenze admin",
    employeeListMaxError: "Hai raggiunto il numero massimo di Dipendenti",

    addUserToAdminListError:
      "Errore: utente non aggiunto alla lista degli admin",
    addUserToAdminListSuccess: "Utenza aggiunta alla lista degli admin",
    removeUserToAdminListSuccess: "Utenza Rimossa Correttamente",
    removeUserToAdminListError: "Errore nella rimozione dell'utenza",
    addUserToEmployeeListSuccess: "Utenza aggiunta alla lista dei Dipendenti",
    addUserToEmployeeListError:
      "Errore: utente non aggiunto alla lista dei dipendenti",

    deleteGarageTitle: "Sei sicuro di voler cancellare il Garage?",
    deleteGarageSuccess: "Garage Cancellato Correttamente",
    deleteGarageError: "Errore Cancellazione Garage",
    addGarageSuccess: "Garage creato correttamente",
    addGarageError: "Errore nel salvataggio del Garage",
    updateGarageSuccess: "Garage Modificato correttamente",
    updateGarageError: "Errore nella modifica del Garage",
    logoModalTitle: "Crea il Garage per caricare il Logo",
    logoModalDesc:
      "Compila il form per la creazione del garage e salva le modifiche prima di caricare il logo",
    addLogoSuccess: "Logo caricato correttamente",
    addLogoError: "Errore aggiornamento dati garage",
    updatePriceSuccess: "Listino prezzi Aggiornato",
    updatePriceError: "Errore nel Salvataggio del nuovo prezziario",
    updatePaymentDaySuccess: "Modifiche Salvate",
    updatePaymentDayError: "Errore nel Salvataggio delle modifiche",
    deletePriceTitle: "Sei sicuro di voler cancellare il Canone ",
    deletePriceSuccess: "Conne eliminato dal Listino prezzi",
    deletePriceError: "Errore nella cancellazione del nuovo canone",
    deleteHourlyPriceTitle: "Sei sicuro di voler cancellare il Prezzo ",
    deleteHourlyPriceSuccess: "Preziario eliminato Correttamente",
    deleteHourlyPriceError: "Errore nella cancellazione del preziario",
    addContractSuccess: "Contratto Aggiornato correttamente",
    addContractError: "Errore nel salvataggio del Contratto",
    printerAddSuccess: "Device correttamente settata",
    printerAddError: "Errore Inizializzazione Device",
    printerExist: "Device già presente a sistema",
    printerUpdateSuccess: "Device Aggiornato",
    printerDeleteSuccess: "Device Eliminato",
    printerUpdateError: "Errore Aggiornamento Device",
  },
  user: {
    userDataReadError: "Errore lettura dati utente",
    imageUploadSuccess: "Immagine caricata correttamente",
    imageUploadError: "Errore aggiornamento dati utente",
    userUpdateSuccess: "Utente Aggiornato correttamente",
  },
  license: {
    licenseTypeDataReadError: "Errore lettura dati Tipo Licenza",
    licenseDataReadError: "Errore lettura dati Licenza",
    licenseDataUpdateError: "Errore Aggiornamento dati Licenza",
    renewLicenseError:
      "Errore: aggiornamento rinnovo licenza - contattare l'assistenza",
    renewLicenseTitleSuccess: "Licenza Rinnovata!",
    renewLicenseSuccess: "Rinnovo eseguito correttamente!",
    disableUserFromLicense: "Utenza disabilitata dalla tua licenza",
    updateLicenseSuccess: "Licenza Aggiornata Correttamente",
    updateLicenseError: "Erorre Aggiornamento Licenza",
    cancelPayment: "Il Pagamento è stato annullato",
    activatedLicenseTitle: "Licenza Attivata!",
    activatedLicenseDesc: "Puoi iniziare ad utilizzare la piattaforma!",
    activatedLicenseErrorTitle: "Errore Attivazione!",
    activatedLicenseErrorDesc:
      "Il pagamento è andato a buon fine  ma ci sono stati problemi nella memorizzazione dei dati. Contatta il servizio clienti!",
  },
  customer: {
    addCustomerSuccess: "Cliente Aggiunto Correttamente",
    addCustomerError: "Errore nella creazione dell'utente",
    deleteCustomerTitle: "Sei sicuro di voler cancellare il Cliente ",
    deleteCustomerDesc: "Tutti i dati associati verranno persi",
    deleteCustomerSuccess: "Cliente Cancellato Correttamente",
    deleteCustomerError: "Errore Cancellazione Cliente",
    updateCustomerSuccess: "Cliente Modificato Correttamente",
    updateCustomerError: "Errore nella modifica del Cliente",
    customerReadError: "Errore caricamento lista clienti",
    customerDownloadError: "Errore nel download della scheda cliente",
  },
  toDoService: {
    addServiceTitle: "Aggiungi un Servizio",
    moveServiceSuccess: "Servizio Spostato in Agenda",
    addServiceSuccess: "Servizio Aggiunto Correttamente",
    addServiceError: "Errore Creazione Servizio",
    updateServiceError: "Errore aggiornamento Servizio",
    updateServiceSuccess: "Servizio Aggiornato Correttamente",
    serviceExecuted: "Il Servizio è stato già eseguito",
    deleteServiceTitle: "Sei sicuro di voler cancellare il Servizio ",
    deleteServiceSuccess: "Servizio eliminato Correttamente",
    deleteServiceError: "Errore nella cancellazione Servizio",
    approvedService: "Servizio Approvato: è stata inviata notifica al cliente",
    serviceRequired: "Devi inserire almeno un servizio",
    vehicleRequired: "Devi inserire almeno un Veicolo",
    serviceNotFound: "Servizio non trovato a sistema",
    toDoServiceDownloadError: "Errore nel download della Lista Servizi",
    validPeriodError: "Inserisci un Periodo Valido",
    validTicketError: "Devi selezionare una Ricevuta",
  },
  ticket: {
    ticketCreationError: "Errore Creazione Ricevuta",
    ticketCreationSuccess: "Ricevuta Creata Correttamente",

    ticketUpdateError: "Errore Aggiornamento Ricevuta",
    ticketUpdateSuccess: "Ricevuta Aggiornata correttamente",

    ticketGenerationSuccess:
      "Ricevute create correttamente per il periodo selezionato",
    ticketGenerationError: "Errore nella creazione delle ricevute",

    ticketDeleteTitle: "Sei sicuro di voler eliminare la ricevuta?",
    ticketDeleteSuccess: "Ricevuta Eliminata Correttamente",
    ticketeDeleteError: "Errore nella cancellazione della ricevuta",

    undo: "Operazione Annullata",

    ticketRevertTitle: "Sei sicuro di voler stornare la ricevuta?",
    ticketeRevertSuccess: "Ricevuta Stornata Correttamente",
    ticketReverError: "Errore aggiornamento della ricevuta",
    ticketDownloadError: "Errore nel download della ricevuta",

    sendTicketSuccess: "Email inviata correttamente",
    sendTicketError: "Errore nell'invio della email",

    paymentTypeTitle: "Seleziona un metodo di pagamento!",

    existingticket:
      "Ricevuta già presente a sistema per il Cliente nel Periodo Selezionato",

    duplicatedTicketTitle: "Ricevuta Duplicata",
  },
  hourlyReservation: {
    startParkingSuccess: "Inizio Sosta Confermato",
    startParkingError: "Errore nell'avvio della sosta",
    endParkingSuccess: "Fine Sosta Confermato",
    endParkingError: "Errore nella terminazione della sosta",
    noPrinter: "Nessuna stampante associata per questo garage",
    deleteHourlyReservationSuccess: "Soste Eliminate",
    deleteHourlyReservationError: "Errore nell Aggiornamento",
  },

  paypalAPI: {
    cancellationError: "Errore nella cancellazione della Sottoscrizione",
    cancellationOk: "Sottoscrizione Cancellata Correttamente",
    paypalSubscriptioneError: "Errore nel Caricamento della Sottoscrizione",
  },
  support: {
    createSupportSuccess:
      "La tua richiesta di supporto è stata correttamente elaborata",
    createSupportError:
      "Errore nella creazione del Ticket verrà comunque tentato l'invio via mail",
  },
  history: {
    resetHistorySuccess: "Mensilità ripristinata",
    resetHistoryError: "Errore nel rispristino della mensilità",
    resetPaymentSuccess: "Pagamenti ripristinati",
    resetPaymentError: "Errore nel rispristino dei pagamenti",
  },
};

export const apiUrl = {
  payPalToken: "https://api.sandbox.paypal.com/v1/oauth2/token",
  payPalSubscription:
    "https://api.sandbox.paypal.com/v1/billing/subscriptions/",
  deepLink: "https://dev.parkingless.com/hourlyReservations/",
};

export const licenseDisconut = {
  discountList: [
    {
      name: "copam",
      code: "1234",
      discount: 16.66,
      subscriptionList: [
        {
          name: "Licenza Base",
          rate: "semestrale",
          printer: false,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Base",
          rate: "semestrale",
          printer: true,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Base",
          rate: "annuale",
          printer: true,
          subscriptionId: "xxxxxx",
        },
        {
          name: "Licenza Oro",
          rate: "semestrale",
          printer: false,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Oro",
          rate: "semestrale",
          printer: true,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Oro",
          rate: "annuale",
          printer: true,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Premium",
          rate: "semestrale",
          printer: false,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Premium",
          rate: "semestrale",
          printer: true,
          subscriptionId: "xxxxx",
        },
        {
          name: "Licenza Premium",
          rate: "annuale",
          subscriptionId: "xxxxx",
        },
      ],
    },
  ],
};
